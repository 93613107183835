<template lang="pug">
.user-typing-message 
  span User is typing {{ dots }}
</template>
<script lang="ts">
import { ChatType } from "@/types";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class Typing extends Vue {
  @Prop({ required: true }) readonly type!: ChatType | "broadcast";
  private timeout?: NodeJS.Timeout;
  private dots = "";
  mounted(): void {
    let i = 0;
    this.timeout = setInterval(() => {
      this.dots = ".".repeat((++i % 3) + 1);
    }, 300);
  }
  destroyed(): void {
    clearInterval(this.timeout);
    this.timeout = void 0;
  }
}
</script>
<style scoped lang="scss">
.user-typing-message span {
  font-size: 16px;
  margin: auto 0.7rem;
}
</style>
