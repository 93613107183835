import { http } from "@/http";
import Routes from "@/services/routes";
import { DeliveryEventModel } from "@/types/event";

const Event = {
  async getEvent(id: string): Promise<DeliveryEventModel | null> {
    const url = `${Routes.EVENT}/${id}?settings=true`;
    try {
      const event = await http.get<DeliveryEventModel>(url);
      if (event.status === 404) throw new Error("Event not found");
      return event.data;
    } catch (err) {
      console.error("Error querying learning event ", err);
      return null;
    }
  },
  close(id: string): Promise<void> {
    const url = `${Routes.EVENT}/${id}`;
    return http.put(`${url}/close`);
  },
  reopen(id: string): void {
    const url = `${Routes.EVENT}/${id}`;
    http.put(`${url}/reopen`);
  },
};
export default Event;
