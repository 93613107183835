<template lang="pug">
.cast-learner-container
  .cast-button-content
    .cast-learner-button(:class="castClass", @click="onCast")
      Icon(value="cast")
      span {{ btnText }}
</template>
<script lang="ts">
import config from "@/config";
import { BrowserMessageEvent, MessageType, SendWindowMessage } from "@/types/browser";
import { Frame } from "@/types/course";
import { ActiveTimer } from "@/types/timer";
import { Icon } from "@cruciallearning/puddle/components";
import { getTimerStr } from "@cruciallearning/puddle/utils";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
@Component({
  components: { Icon },
  computed: {
    ...mapState("BrowserModule", ["learnerWindow"]),
    ...mapState("TimerModule", ["active"]),
    ...mapState("VideoModule", ["overlay"]),
    ...mapState("CourseModule", ["showModel", "showAltModel"]),
    ...mapGetters("CourseModule", ["getActiveContent", "getLearnerPageNumber", "getModelUrl", "getAltModelUrl"]),
    ...mapGetters("BrowserModule", ["isCasting"]),
  },
  methods: {
    ...mapActions("BrowserModule", ["openExtendedView", "closeExtendedView", "messageSecondDisplay"]),
  },
})
export default class CastDisplay extends Vue {
  private readonly isCasting!: boolean;
  private readonly overlay!: boolean;
  private readonly showModel!: boolean;
  private readonly showAltModel!: boolean;
  private getModelUrl!: string;
  private getAltModelUrl!: string;
  private readonly active!: ActiveTimer;
  private getLearnerPageNumber!: number;
  private readonly getActiveContent!: Partial<Frame[]>;
  private readonly messageSecondDisplay!: SendWindowMessage;
  private readonly openExtendedView!: () => void;
  private readonly closeExtendedView!: () => void;
  get castClass(): string {
    return this.isCasting ? "casting-enabled" : "casting-disabled";
  }
  get btnText(): string {
    return `${this.isCasting ? "Close" : "Expand"} Learner Window`;
  }

  onCast(): void {
    const messageListener = (event: BrowserMessageEvent) => {
      if (event.origin !== config.frostUrl) return;
      if (!event.isTrusted) return;
      const message = event.data.message;
      if (message) {
        const type = message.type;
        if (type == undefined) return;
        if (type == MessageType.LEARNER_WINDOW_CLOSED) {
          this.closeExtendedView();
        }
        if (type == MessageType.INITIALIZED) {
          this.contentUpdate();
        }
      }
    };

    const beforeUnload = () => {
      this.closeExtendedView();
    };
    if (this.isCasting) {
      window.removeEventListener("message", messageListener);
      window.removeEventListener("beforeunload", beforeUnload);
      this.closeExtendedView();
    } else {
      window.addEventListener("message", messageListener.bind(this));
      window.addEventListener("beforeunload", beforeUnload);
      this.openExtendedView();
    }
  }

  @Watch("getActiveContent")
  contentUpdate(): void {
    const url = this.getActiveContent[0]?.content?.image.url;
    if (url) {
      this.messageSecondDisplay({ type: MessageType.SLIDE_NAVIGATION, content: url });
      this.messageSecondDisplay({ type: MessageType.PAGE_NUMBER, content: `${this.getLearnerPageNumber}` });
    }
  }

  @Watch("showModel")
  @Watch("showAltModel")
  courseModel(): void {
    let imgUrl = "";
    if (!this.showAltModel && !this.showModel) imgUrl = this.getActiveContent[0]?.content?.image.url || "";
    else if (this.showModel) imgUrl = this.getModelUrl;
    else if (this.showAltModel) imgUrl = this.getAltModelUrl;
    this.messageSecondDisplay({ type: MessageType.SLIDE_NAVIGATION, content: imgUrl });
  }

  @Watch("active")
  setTimerDisplay(): void {
    let timeDisplay = "";
    if (this.active) {
      timeDisplay = getTimerStr(Math.round(this.active.time / 1000));
    }
    this.messageSecondDisplay({ type: MessageType.TIMER_UPDATE, content: `${timeDisplay}` });
  }

  @Watch("overlay")
  setOverlay(): void {
    const message = this.overlay ? MessageType.APPLY_OVERLAY : MessageType.REMOVE_OVERLAY;
    const providerId = this.getActiveContent[1]?.video?.providerId || "";
    this.messageSecondDisplay({ type: message, content: providerId });
  }
}
</script>
<style lang="scss" scoped>
.cast-button-content {
  height: 36px;
}
.cast-learner-button {
  height: 100%;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  padding: 0 0.5rem 0 0;
  span {
    color: white;
    display: flex;
    margin: auto;
    font-weight: bold;
    font-size: 0.8rem;
    text-wrap: nowrap;
  }
  &.casting-enabled {
    border: 1px solid white;
    &:hover {
      background-color: var(--gray-80);
    }
    background-color: black;
  }
  &.casting-disabled {
    &:hover {
      background-color: var(--info-20);
    }
    background-color: var(--info-50);
  }
  ::v-deep {
    svg {
      height: 0.85rem;
      width: 0.85rem;
      margin: 0 0.5rem;
      path {
        fill: white;
      }
    }
  }
}
</style>
