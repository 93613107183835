import {
  BreakoutPayload,
  ChatPayload,
  ClassroomPayload,
  DisconnectPayload,
  GeneralPayload,
  PollPayload,
  PingPayload,
  StreamPayload,
  TestPayload,
} from "@/types";
import breakout from "./requests/breakout";
import chat from "./requests/chat";
import classroom from "./requests/classroom";
import disconnect from "./requests/disconnect";
import general from "./requests/general";
import ping from "./requests/ping";
import poll from "./requests/poll";
import reaction from "./requests/reaction";
import stream from "./requests/stream";
import test from "./requests/test";
import video from "./requests/video";
import signal from "signal-js";
import slide from "./requests/slide";
import timer from "./requests/timer";
import annotator from "./requests/annotation";
import { AnnotatorPayload } from "@/types/Annotation";
import { ReactionPayload } from "@/types/reaction";
import remoteLogger from "@/services/socket_api/requests/remoteLogger";
import { SlidePayload } from "@/types/slides";
import { TimerPayload } from "@/types/timer";
import { VideoPayload } from "@/types/video";

class Sockets {
  public annotator = annotator;
  public breakout = breakout;
  public chat = chat;
  public classroom = classroom;
  public disconnect = disconnect;
  public general = general;
  public poll = poll;
  public ping = ping;
  public remoteLogger = remoteLogger;
  public reaction = reaction;
  public stream = stream;
  public test = test;
  public video = video;
  public slide = slide;
  public timer = timer;

  constructor() {
    //App Specific Receive
    signal.on("annotator", (payload: AnnotatorPayload) => this.annotator.receive(payload));
    signal.on("breakout", (payload: BreakoutPayload) => this.breakout.receive(payload));
    signal.on("chat", (payload: ChatPayload) => this.chat.receive(payload));
    signal.on("classroom", (payload: ClassroomPayload) => this.classroom.receive(payload));
    signal.on("disconnect", (payload: DisconnectPayload) => this.disconnect.receive(payload));
    signal.on("general", (payload: GeneralPayload) => this.general.receive(payload));
    signal.on("poll", (payload: PollPayload) => this.poll.receive(payload));
    signal.on("ping", (payload: PingPayload) => this.ping.receive(payload));
    signal.on("reaction", (payload: ReactionPayload) => this.reaction.receive(payload));
    signal.on("test", (payload: TestPayload) => this.test.receive(payload));
    signal.on("video", (payload: VideoPayload) => this.video.receive(payload));
    signal.on("vonage", (payload: StreamPayload) => this.stream.receive(payload));
    signal.on("slide", (payload: SlidePayload) => this.slide.receive(payload));
    signal.on("timer", (payload: TimerPayload) => this.timer.receive(payload));
  }
}
const sockets = await new Sockets();
export { sockets };
