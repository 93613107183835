import { DeliveryEventState } from "@/types/event";
import { Module } from "vuex";

const EventModule: Module<DeliveryEventState, unknown> = {
  namespaced: true,
  state(): DeliveryEventState {
    return {
      event: {
        audit: {},
        clientName: "",
        courseId: "",
        courseName: "",
        clientId: "",
        details: "",
        eventStatus: "",
        expirationDate: new Date(),
        expired: false,
        id: "",
        includesPhysicalBook: false,
        learnerMaterials: [],
        learningFormatType: "",
        learningSessions: [],
        preWorkMaterialIds: [],
        redeemedCount: 0,
        redemptionCodes: [],
        registrationCode: {
          availableCount: 0,
          code: "",
          redeemedCount: 0,
          registrants: [],
          endDate: new Date(),
          startDate: new Date(),
        },
        registrationType: "",
        salesForceId: "",
        seatCount: 0,
        title: "",
        originalMaxCount: 0,
        originalSeatCount: 0,
        referenceNumber: "",
        practice: false,
        contentStackProductId: "",
        courseContentStackId: "",
        sessionTimeZone: {
          id: "",
          offset: 0,
          title: "",
        },
        clientCourseTokens: [],
        eap: [],
        startDate: new Date(),
        courseNotes: [],
        materialDownloads: [],
        onDemandCourseId: "",
        onDemandEventId: "",
        skipAutoReclaim: false,
        observerIds: [],
        pendingCode: false,
        systemCheck: false,
        deliveryConfig: {
          features: {},
          key: "",
          id: "",
        },
      },
    };
  },
  getters: {
    sessionNumber(state: DeliveryEventState): number {
      const toDoSessionIds = state.event.learningSessions
        .filter((e) => !e.completed && !e.skipped)
        .map((e) => e.sessionId);
      if (toDoSessionIds.length == 0) return -1;
      return Math.min(...toDoSessionIds);
    },
    restartSessionNumber(state: DeliveryEventState): number {
      const restarting = state.event.learningSessions.filter((e) => e.restarted).map((e) => e.sessionId);
      if (restarting.length == 0) return -1;
      return Math.min(...restarting);
    },
    isRestarting(_, getters): boolean {
      return getters.restartSessionNumber != -1;
    },
    annotationsEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.annotations;
    },
    backstageChatEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.backstageChat;
    },
    breakoutsEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.breakouts;
    },
    broadcastEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.broadcast;
    },
    chatEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.chat;
    },
    learnerGuideEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.learnerGuide;
    },
    reactionsEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.reactions;
    },
    resizeEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.resize;
    },
    streamEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.stream;
    },
    userListEnabled(state: DeliveryEventState): boolean {
      return !!state.event?.deliveryConfig?.features?.userList;
    },
  },
  mutations: {
    setEventState(state: DeliveryEventState, changes: Partial<DeliveryEventState>): void {
      Object.assign(state, changes);
    },
  },
};

export default EventModule;
