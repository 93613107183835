<template lang="pug">
#secondary-video.pointer-event
  .videoPlayer(:class="videoClasses")
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

import { mapMutations } from "vuex";
import { SetVideoState, VideoConfig, VideoControls } from "@/types/video";
@Component({
  methods: {
    ...mapMutations("VideoModule", ["setVideoState"]),
  },
})
export default class SecondaryVideo extends Vue {
  @Prop({ required: true }) providerId!: string;

  private readonly setVideoState!: SetVideoState;
  private readonly playing!: boolean;
  private readonly position!: number;

  private video?: VideoControls;
  private videoClasses = "";
  private videoConfig?: Partial<VideoConfig>;
  private videoScript?: string;
  private wistiaScript = "https://fast.wistia.com/assets/external/E-v1.js";

  async mounted(): Promise<void> {
    window._wq = window._wq || [];
    this.videoConfig = {
      id: this.providerId,
      options: {
        videoFoam: true,
        wmode: "transparent",
        volumeControl: true,
        fullscreenButton: false,
        smallPlayButton: false,
        autoPlay: false,
        controlsVisibleOnLoad: false,
        playbar: false,
        copyLinkAndThumbnailEnabled: false,
        playButton: false,
        plugin: {
          "captions-v1": {
            transcript: false,
            onByDefault: true,
          },
        },
      },
      onReady: (video: VideoControls): void => {
        this.video = video;
        this.video.height(window.innerHeight, { constrain: true });
      },
    };
    window._wq.push(this.videoConfig);
    this.videoScript = `https://fast.wistia.com/embed/medias/${this.providerId}.jsonp`;
    await this.$loadscript.load(this.videoScript);
    await this.$loadscript.load(this.wistiaScript);
    this.videoClasses = `wistia_embed wistia_async_${this.providerId}`;
    window.addEventListener("resize", this.maxOutHeight);
  }
  maxOutHeight(): void {
    this.video?.height(window.innerHeight, { constrain: true });
  }
  beforeDestroy(): void {
    window._wq.push({ revoke: this.videoConfig });
    window.removeEventListener("resize", this.maxOutHeight);
  }
  async destroyed(): Promise<void> {
    this.setVideoState({ overlay: true, playing: false });
    this.video?.remove();
    await this.$loadscript.unload(this.videoScript as string).catch(() => undefined);
    await this.$loadscript.unload(this.wistiaScript).catch(() => undefined);
    this.video = void 0;
    this.videoConfig = void 0;
    this.videoScript = void 0;
  }

  play(pos: number): void {
    this.video?.time(pos);
    this.video?.play();
  }
  pause(): void {
    this.video?.pause();
  }

  setPosition(pos: number): void {
    this.video?.time(pos);
  }
}
</script>
<style scoped lang="scss">
#secondary-video {
  position: relative;
  width: 100%;
  max-height: 100vh;
  margin: auto;
}
button i {
  margin-right: 0;
}

.close {
  position: absolute;
  padding-right: 12px;
  top: 0;
  right: 0;
}

.videoPlayer {
  height: 100%;
  padding: 0;
  width: 100%;
  margin: auto;
}
.pointer-event {
  pointer-events: none;
}
</style>
