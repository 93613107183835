<template lang="pug">
.system-check-card
  .system-check-card-title.has-line
    span System Check Results
  .error-legend(v-if="!success")
    .no-icon-legend
      span(v-html="techSpecMessage")
  .results-report-contents
    .system-check-description
      .steps-passed(v-if="success")
        .report-title 
          span You're Almost Ready!
        .report-subtext 
          span Your system meets all the requirements for a CLP learning event!
      .steps-failed(v-else)
        ul.list-unstyled
          li(v-for="(step, index) in getFailedSteps")
            .failed-step
              Icon(v-if="isStepRequired(step.name)", value="x-octagon", color="red")
              Icon(v-else, value="alert-triangle", color="#F75301")
              .failed-step-info
                .failed-step-title
                  span {{ step.name }}
                span(v-html="step.error", :class="failedClass(step)")
                .retake-step-button.button.secondary(@mousedown="onRetakeClick(step)")
                  Icon(value="arrow-left", color="var(gray-80)")
                  span Retake {{ step.name }}
</template>
<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep.vue";
import { mapGetters, mapMutations } from "vuex";
import { GetFailedSteps, SystemCheckStep } from "@/types/SystemCheck";
import { Icon } from "@cruciallearning/puddle/components";
import Status from "./Status.vue";
import { htmlTechSpec } from "./TechSpecs.vue";
@Component({
  components: {
    Icon,
    Status,
    htmlTechSpec,
  },
  computed: {
    ...mapGetters("SystemCheckModule", ["getFailedSteps", "getStepByName", "isStepRequired"]),
  },
  methods: {
    ...mapMutations("SystemCheckModule", ["retakeStep"]),
  },
})
export default class ResultsReport extends BaseStep {
  private readonly getFailedSteps!: GetFailedSteps;
  private readonly retakeStep!: (name: string) => void;
  private readonly getStepByName!: (name: string) => SystemCheckStep;
  get success(): boolean {
    return this.getFailedSteps.length == 0;
  }
  get techSpecMessage(): string {
    return `To troubleshoot any issues please direct your I.T. department to the ${htmlTechSpec}`;
  }
  get failedClass(): (selected: SystemCheckStep) => string {
    return (selected: SystemCheckStep) => {
      const step = this.getStepByName(selected.name);
      return step?.required ? "failed-step-error" : "failed-step-caution";
    };
  }
  onRetakeClick(step: SystemCheckStep): void {
    this.retakeStep(step.name);
  }
}
</script>
<style lang="scss" scoped>
.steps-passed {
  margin-top: 1rem;
  display: inline-block;
}
.report-title {
  margin-top: 1rem;
}
.report-title span {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}
.steps-failed .report-subtext {
  margin: 1rem 0;
  border-bottom: var(--gray-80) 1px solid;
}
::v-deep {
  .report-subtext span {
    line-height: 1.5rem;
    font-size: 0.85rem;
    &:has(svg) {
      display: inline-block;
      svg {
        vertical-align: sub;
      }
    }
  }
}

.steps-passed .button {
  margin-left: 0;
}
.failed-step {
  display: flex;
}
.failed-step-title {
  margin-bottom: 10px;
}
.failed-step-title span {
  font-weight: bold;
}
.steps-failed ul li {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}
.failed-step-caution ::v-deep ul,
.failed-step-error ::v-deep ul {
  list-style-type: none;
}
.failed-step-caution ::v-deep ul li:first-child,
.failed-step-error ::v-deep ul li:first-child {
  margin-bottom: 1rem;
}

.failed-step-caution ::v-deep ul li:first-child::before {
  content: "CAUTION";
  margin-right: 5px;
  color: #f75301;
  font-weight: bold;
}
.failed-step-caution ::v-deep ul li:first-child::after {
  content: "This item needs action before you join an event. If you proceed, your experience may suffer or fail.";
  color: black;
  font-weight: bold;
  margin-left: 4px;
}
.failed-step-caution ::v-deep ul li:first-child {
  background-color: rgba(247, 83, 1, 0.05);
  border: solid #f75301 1px;
  padding: 10px;
  border-radius: 6px;
}

.failed-step-error ::v-deep ul li:first-child::before {
  content: "FAIL";
  margin-right: 5px;
  color: #ed1b2f;
  font-weight: bold;
}
.failed-step-error ::v-deep ul li:first-child::after {
  content: "This item MUST be resolved to redeem your license.";
  color: black;
  font-weight: bold;
  margin-left: 4px;
}
.failed-step-error ::v-deep ul li:first-child {
  background-color: rgba(237, 27, 47, 0.05);
  border: solid #ed1b2f 1px;
  padding: 10px;
  border-radius: 6px;
}

.error-legend {
  margin: 2rem 0;
}
.icon {
  padding-right: 0 !important;
  min-width: 2.5rem;
}
.no-icon-legend {
  display: inline-block;
  margin-left: 2.5rem;
}
.retake-step-button {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 50px;
  margin: 0 0 1rem 0 !important;
  max-height: 36px;
}
.retake-step-button ::v-deep path {
  transition: all 0.4s ease-in;
}
.retake-step-button span:nth-child(2) {
  text-align: center;
  font-family: Flama;
  font-size: 14px;
  font-style: normal;
  font-weight: 750;
  line-height: 130%;
  text-transform: capitalize;
}
.retake-step-button ::v-deep .icon {
  min-width: unset;
  svg {
    width: 16px;
    height: 16px;
  }
}
.results-report-contents {
  width: 608px;
}
.failed-step-info > span {
  font-size: 16px;
}
</style>
