import { ChatType, ClassroomModel, ClassroomPayload, UserListModel } from "@/types";
import chat from "@/services/socket_api/requests/chat";
import routes from "@/services/routes";
import socket from "@/services/socket";
import store from "@/store";
import Authorization from "@/auth/authorization";
import api from "@/services/api";
import { getInstance } from "@cruciallearning/puddle/auth";
import GroupChatUtil from "@/utils/GroupChatUtil";

const classroom = {
  connect(): void {
    socket.send({ type: routes.CLASSROOM_JOIN });
  },

  start(): void {
    socket.send({ type: routes.CLASSROOM_START });
  },

  restart(): void {
    socket.send({ type: routes.CLASSROOM_RESTART });
  },

  stop(): void {
    socket.send({ type: routes.CLASSROOM_STOP });
  },

  admit(id: string): void {
    socket.send({ type: routes.CLASSROOM_ADMIT, id });
  },

  leave(id?: string): void {
    socket.send({ type: routes.CLASSROOM_LEAVE, id });
  },

  removeAll(): void {
    socket.send({ type: routes.CLASSROOM_REMOVE_ALL });
  },

  async receive(payload: ClassroomPayload): Promise<void> {
    switch (payload.type) {
      case routes.CLASSROOM_JOIN:
      case routes.CLASSROOM_START:
      case routes.CLASSROOM_STATUS: {
        console.log("Received classroom payload: ", payload);
        const model = payload.body as ClassroomModel;
        if (model && model.users) {
          const event = store.state.EventModule?.event;
          const reloadEvent = model.users.find(
            (user) =>
              !Authorization.isManager(user.securityId) && !Authorization.isRegisteredLearner(user.securityId, event)
          );
          if (reloadEvent) {
            console.log("Updating learning event for new users");
            const event = await api.event.getEvent(store.state.trainingEventId);
            if (event) {
              store.commit("EventModule/setEventState", { event });
            } else {
              console.error("Missing event, expect possible user list issues");
            }
          }
          store.commit("UsersModule/setUsers", model.users);
        }
        // Set Started State
        if (model.started !== null || void 0) {
          if (store.state.isClassroomStarted !== model.started) {
            store.dispatch("classroomStateChange", { isClassroomStarted: model.started });
          }
        }
        // Set Connected State
        if (!store.state.isClassroomConnected) {
          store.dispatch("classroomStateChange", { isClassroomConnected: true });
        }
        if (model.annotatorId) {
          store.commit("AnnotationsModule/setAnnotationState", { annotatorId: model.annotatorId });
        }
        if (model.color) {
          store.commit("AnnotationsModule/setAnnotationState", { color: model.color });
        }
        if (model.trainerIds) {
          store.commit("UsersModule/setUsersState", { trainerIds: model.trainerIds });
        }
        if (model.moderatorIds) {
          store.commit("UsersModule/setUsersState", { moderatorIds: model.moderatorIds });
        }
        /* Observer */
        if (model.observerIds) {
          store.commit("UsersModule/setUsersState", { observerIds: model.observerIds });
        }
        /* End Observer */

        //Set one time info
        if (store.state.isInitialLoad) {
          // Populate Main Chat
          if (model.messages) chat.populateChat(model.messages, ChatType.MAIN);
          // Only admins will receive backstage messages in response
          if (model.backstageMessages) chat.populateChat(model.backstageMessages, ChatType.BACKSTAGE);
          // Populate Waiting Room chat
          if (model.lobbyMessages) chat.populateChat(model.lobbyMessages, ChatType.LOBBY);
          // Set Current Slide and video Position
          if (model.videoInfo && model.videoInfo.state === "PLAY") {
            store.dispatch("VideoModule/playVideo", { position: model.videoInfo.position });
          }
          if (model.useModel) store.commit("CourseModule/setCourseState", { showModel: model.useModel });
          if (model.useAltModel) store.commit("CourseModule/setCourseState", { showAltModel: model.useAltModel });
          if (model.slideId) store.dispatch("CourseModule/navigateReceived", model.slideId);
          if (model.pinned) store.commit("ChatModule/setPinnedMessages", { pinned: model.pinned });
          if (model.statuses) store.commit("UsersModule/populateStatuses", model.statuses);
          if (model.activePollSlide)
            store.commit("PollModule/setPollState", { activePollSlide: model.activePollSlide });
          if (model.timerSettings && model.timerSettings.sound)
            store.commit("TimerModule/setTimerState", { sound: model.timerSettings.sound });
          if (model.timerSettings && model.timerSettings.muted)
            store.commit("TimerModule/setTimerState", { muted: model.timerSettings.muted });
          if (model.timerSettings) store.dispatch("TimerModule/timerJoin", model.timerSettings);
          if (model.breakoutInfo) {
            store.dispatch("BreakoutsModule/populateBreakoutInfo", model.breakoutInfo);
          }
          GroupChatUtil.openCached();
          store.dispatch("update", { isInitialLoad: false });
        }
        break;
      }
      case routes.CLASSROOM_ADMIT:
        store.commit("UsersModule/setUsers", (payload.body as UserListModel).users);
        break;
      case routes.CLASSROOM_LEAVE: {
        const body = payload.body as UserListModel;
        const securityId = getInstance().authUser.id;
        const self = body.users.find((item) => item.securityId === securityId);
        if (!self) {
          store.dispatch("disconnect", true);
        } else store.commit("UsersModule/setUsers", (payload.body as UserListModel).users);
        break;
      }
      case routes.CLASSROOM_STOP:
        socket.disconnect();
        store.commit("setRootState", { wasDisconnected: true, isClassroomStarted: false }, { root: true });
        break;
      default:
        store.dispatch("classroomError", ["Invalid classroom message"]);
    }
  },

  status(): void {
    socket.send({ type: routes.CLASSROOM_STATUS });
  },
};
export default classroom;
