<template lang="pug">
.practice.cell-12.flex-justify-center.grid-start
  .confirm-practice
    .confirm-practice-dialog
      Icon.alert-circle(value="alert-circle", height="3.5rem", width="3.5rem")
      p.header-message You are using
        br
        | crucial learning platform
        br
        | in practice mode
      p.content-message Practice Mode is intended for practice and preparation
        br
        | and cannot be used for actual training.
      .lets-go-btn(@click="onConfirmClick")
        span Let's Go
</template>
<script lang="ts">
import { SetRootState } from "@/types";
import { BaseButton as Button } from "@cruciallearning/puddle";
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import { Icon } from "@cruciallearning/puddle/components";
@Component({
  components: { Icon, Button },
  methods: {
    ...mapMutations(["setRootState"]),
  },
})
export default class ConfirmPractice extends Vue {
  private readonly setRootState!: SetRootState;
  onConfirmClick(): void {
    this.setRootState({ practiceConfirm: false });
  }
}
</script>
<style lang="scss" scoped>
.practice {
  background: rgb(20, 20, 20, 0.85) !important;
  z-index: 500;
  position: absolute;
  height: 100%;
  width: 100%;
}
.confirm-practice {
  z-index: 15;
}
.confirm-practice-dialog {
  padding-top: 15%;
  padding-left: 10%;
}

::v-deep .alert-circle svg,
::v-deep .alert-circle svg path,
::v-deep .alert-circle svg path g {
  fill: white;
}

.header-message {
  margin-top: 1rem;
  font-size: 3rem;
  line-height: 3rem;
  font-family: flama_condensed;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.content-message {
  font-size: 1.25rem;
}

.button {
  padding: 12px 11px !important;
}
.lets-go-btn {
  display: flex;
  width: 288px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #ff993b;
  cursor: pointer;
}
.lets-go-btn:hover {
  background-color: #fe881a;
}
.lets-go-btn span {
  text-transform: capitalize;
  color: var(--Black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 650;
  line-height: 24px;
  letter-spacing: 0.46px;
}
</style>
