<template lang="pug">
.module-overlay
  .breakout-overlay
    .breakout-overlay-icon
      Icon(:value="iconValue")
    p.breakout-overlay-header(v-if="headerText") {{ headerText }}
    p.breakout-overlay-body(v-if="bodyText") {{ bodyText }}
    p.breakout-overlay-timer(v-if="hasTimer") {{ current }}
    .breakout-overlay-buttons(v-if="hasButton")
      Button.breakout-primary(
        v-if="hasPrimaryButton",
        small,
        :text="primaryButtonText",
        ghost,
        @click="onPrimaryClick"
      )
      Button.breakout-secondary(
        v-if="hasSecondaryButton",
        small,
        :text="secondaryButtonText",
        secondary,
        @click="onSecondaryClick"
      )
</template>
<script lang="ts">
import { Icon } from "@cruciallearning/puddle/components";
import { Component, Vue } from "vue-property-decorator";
import { BaseButton as Button } from "@cruciallearning/puddle";
import { mapMutations, mapState } from "vuex";
import { BreakoutPopup, SetBreakoutPopup } from "@/types";

@Component({
  components: {
    Button,
    Icon,
  },
  computed: {
    ...mapState("BreakoutsModule", ["breakoutPopup"]),
  },
  methods: {
    ...mapMutations("BreakoutsModule", ["setBreakoutPopup"]),
  },
})
export default class BreakoutOverlay extends Vue {
  private readonly breakoutPopup!: BreakoutPopup;
  private readonly setBreakoutPopup!: SetBreakoutPopup;
  private current = -1;
  private timeout?: NodeJS.Timeout;

  mounted(): void {
    if (this.breakoutPopup.timer) {
      this.current = this.breakoutPopup.timer.increment ? 1 : this.breakoutPopup.timer.interval;
      this.timeout = setInterval(() => {
        let complete = false;
        if (this.breakoutPopup.timer?.increment) {
          this.current++;
          if (this.current > this.breakoutPopup.timer.interval) {
            complete = true;
          }
        } else {
          this.current--;
          if (this.current <= 0) {
            complete = true;
          }
        }
        if (complete) {
          this.onTimer();
          clearInterval(this.timeout as NodeJS.Timeout);
        }
      }, 1000);
    }
  }

  destroyed(): void {
    clearInterval(this.timeout as NodeJS.Timeout);
    this.timeout = void 0;
  }

  get headerText(): string {
    return this.breakoutPopup.headerText || "";
  }
  get iconValue(): string {
    return this.breakoutPopup.iconValue || "";
  }
  get bodyText(): string {
    return this.breakoutPopup.bodyText || "";
  }
  get primaryButtonText(): string {
    return this.breakoutPopup?.primaryButton?.buttonText || "";
  }

  get secondaryButtonText(): string {
    return this.breakoutPopup?.secondaryButton?.buttonText || "";
  }

  get hasButton(): boolean {
    return this.hasPrimaryButton || this.hasSecondaryButton;
  }

  get hasPrimaryButton(): boolean {
    return !!this.breakoutPopup.primaryButton;
  }

  get hasSecondaryButton(): boolean {
    return !!this.breakoutPopup.secondaryButton;
  }

  get hasTimer(): boolean {
    return !!this.breakoutPopup.timer;
  }

  onTimer(): void {
    this.breakoutPopup.timer?.callback();
    this.close();
  }
  onPrimaryClick(): void {
    this.breakoutPopup.primaryButton?.callback();
    this.close();
  }

  onSecondaryClick(): void {
    this.breakoutPopup.secondaryButton?.callback();
    this.close();
  }

  close(): void {
    this.setBreakoutPopup(null);
  }
}
</script>
<style lang="scss" scoped>
.breakout-overlay {
  text-align: center;
  width: 100%;
  .breakout-overlay-icon {
    margin-bottom: 20px;
  }
  p {
    color: #fff;
    font-family: Flama;
    font-size: 20px;
    font-style: normal;
    line-height: 140%;
    max-width: 35%;
    text-align: center;
    margin: auto;
  }
  .breakout-overlay-header,
  .breakout-overlay-timer {
    font-weight: bold;
    font-weight: 750;
  }

  ::v-deep svg path {
    fill: white;
  }
  .breakout-overlay-buttons {
    margin-top: 32px !important;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    .button {
      margin: 0 1rem;
    }
  }
  .ghost.breakout-primart {
    background-color: var(--gray-95);
  }
  .ghost.breakout-primary:hover {
    background-color: var(--gray-80);
  }
  .secondary.breakout-secondary {
    background-color: var(--info-50);
  }
  .secondary.breakout-secondary:hover {
    background-color: var(--info-20);
  }
}
</style>
