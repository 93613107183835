<template lang="pug">
.controls
  ul.list-unstyled
    li
      CastDisplay(v-if="broadcastEnabled && $auth.ADMIN")
    template(v-if="streamEnabled")
      li.icon-button(title="Video Control", :class="video", data-name="toggle-video", @click="onPublishVideo")
        i(data-name="toggle-video-icon")
      li.icon-button(title="Audio Control", :class="audio", @click="onPublishAudio", data-name="toggle-audio")
        i(data-name="toggle-audio-icon")
    li(v-if="$auth.ADMIN && !getActiveBreakout && annotationsEnabled")
      #headerAnnotateControls.span.icon-button(@click="onAnnotateClick", :class="annotateButtonStyle")
        i.icon-edit-3
    li.attendee-icon
      #headerAdminControls.span.icon-button(data-name="admin-controls", @click="isMenuVisible = !isMenuVisible")
        i.icon-menu(data-name="icon-menu")
  PopoverMenu(
    target="#headerAdminControls",
    :visible="isMenuVisible",
    popoverPosition="top left",
    targetPosition="bottom left",
    popover-class="dropdown-menu",
    width="225",
    @hiding="onHidingAdminMenu"
  )
    FunctionMenu(@hiding="onHidingAdminMenu", data-name="adminMenu")
  PopoverMenu(
    target="#headerAnnotateControls",
    :visible="isAnnotateMenuVisible",
    popoverPosition="top left",
    targetPosition="bottom left",
    popover-class="dropdown-menu",
    width="275",
    @hiding="onHidingAnnotateMenu"
  )
    Annotate(@hiding="onHidingAnnotateMenu", data-name="annotateMenu")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import FunctionMenu from "@/components/Header/Toolbar/Controls/FunctionMenu.vue";
import { BasePopoverMenu as PopoverMenu } from "@cruciallearning/puddle";
import { Breakout } from "@/types";
import Annotate from "@/components/Header/Toolbar/Controls/Annotate.vue";
import BaseContent from "@/components/Content/BaseContent.vue";
import CastDisplay from "../../CastDisplay.vue";

@Component({
  components: { Annotate, BaseContent, CastDisplay, FunctionMenu, PopoverMenu },
  computed: {
    ...mapState("VonageModule", ["publisher"]),
    ...mapState("AnnotationsModule", ["annotatorId"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf", "getActiveBreakout"]),
    ...mapGetters("AnnotationsModule", ["isAnnotating", "isPenAvailable"]),
    ...mapGetters("EventModule", ["streamEnabled", "annotationsEnabled", "broadcastEnabled"]),
  },
})
export default class Controls extends Vue {
  private readonly publisher!: OT.Publisher | null;
  private readonly getActiveBreakout!: Breakout | undefined;
  private readonly annotatorId!: string;
  private readonly isAnnotating!: boolean;
  private readonly isPenAvailable!: boolean;

  private isMenuVisible = false;
  private isAnnotateMenuVisible = false;

  get annotateButtonStyle(): string {
    if (this.isPenAvailable) return "not-annotating";
    else if (this.isAnnotating) return "self-annotating";
    else return "disable-annotating";
  }

  get audio(): string {
    if (!this.publisher) {
      return "icon-mic-off disabled";
    }
    return this.publisher?.stream?.hasAudio ? "icon-mic on" : "icon-mic-off off";
  }

  get video(): string {
    if (!this.publisher) {
      return "icon-video-off disabled";
    }
    return this.publisher.stream?.hasVideo ? "icon-video on" : "icon-video-off off";
  }
  onHidingAdminMenu(): void {
    this.isMenuVisible = false;
  }
  onHidingAnnotateMenu(): void {
    this.isAnnotateMenuVisible = false;
  }
  onPublishAudio(): void {
    this.publisher?.publishAudio(!this.publisher?.stream?.hasAudio);
  }

  onPublishVideo(): void {
    this.publisher?.publishVideo(!this.publisher?.stream?.hasVideo);
  }

  onAnnotateClick(): void {
    if (this.isAnnotateMenuVisible) this.isAnnotateMenuVisible = false;
    if (this.annotatorId == "UNSET" || this.annotatorId == this.$auth.authUser.id) {
      this.isAnnotateMenuVisible = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.self-annotating {
  color: var(--success-20);
}
.not-annotating {
  color: var(--error-20);
}
.disable-annotating {
  color: var(--gray-20);
}
.disable-annotating:hover {
  color: var(--gray-20);
  background-color: var(--gray-80);
}
</style>
