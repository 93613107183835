import store from "@/store";
import { avatarStyle } from "@/utils";
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter";
import { http } from "@/http";
import { LocalStorageFields } from "@/types/base";

const VonageUtil = {
  getSecurityId(stream?: OT.Stream): string {
    if (!stream) return "";
    return JSON.parse(stream.connection.data).securityId;
  },
  userNameNode(securityId: string | undefined, connectionData: string | undefined): HTMLDivElement | undefined {
    if (connectionData) {
      const name = this.getNameFromConnection(connectionData);
      const firstName = name.firstName;
      const lastName = name.lastName;
      const nameDiv = document.createElement("div");
      nameDiv.classList.add("video-user-name");
      const nameSpan = document.createElement("span");
      nameSpan.append(`${firstName} ${lastName}`);
      nameDiv.append(nameSpan);
      nameSpan.id = `user-name-${securityId}`;
      nameSpan.style.paddingLeft = "30px";
      return nameDiv;
    } else {
      console.log("no connection data");
    }
  },
  menuIconNode(securityId: string): HTMLDivElement {
    const div = document.createElement("div");
    const safeId = VonageUtil.userClass(securityId);
    const divId = `video-actions-${safeId}`;
    div.id = divId;
    div.classList.add("icon-video-button");
    const icon = document.createElement("i");
    icon.classList.add("icon-dots-three-outline");
    div.addEventListener("click", () => {
      GlobalEventEmitter.$emit("videoClickMenu", { divId: `#${divId}`, userId: securityId });
    });
    div.append(icon);
    return div;
  },

  chatIconNode(securityId: string): HTMLDivElement {
    const div = document.createElement("div");
    div.classList.add("icon-video-button");
    const icon = document.createElement("i");
    icon.classList.add("icon-message-circle");
    div.addEventListener("click", () => {
      store.dispatch("ChatModule/messageParticipant", securityId);
    });
    div.append(icon);
    return div;
  },

  hiddenVideoNode(connectionData: string | undefined): HTMLDivElement | undefined {
    if (connectionData) {
      const name = this.getNameFromConnection(connectionData);
      const first = name.firstName;
      const last = name.lastName;
      const div = document.createElement("div");
      div.classList.add("video-off-initials");
      const clipContainer = document.createElement("div");
      clipContainer.classList.add("initial-clip-container");
      const clip = document.createElement("div");
      clip.classList.add("initial-clip");
      const p = document.createElement("p");
      const style = `${avatarStyle(first, last)};clip-path: circle()`;
      p.setAttribute("style", style);
      const initials = `${first.charAt(0)}${last.charAt(0)}`;
      p.append(initials);
      clip.append(p);
      clipContainer.append(clip);
      div.append(clipContainer);
      return div;
    }
  },

  mutedIndicatorNode(securityId: string | undefined, mutedIconSrc: string): HTMLDivElement {
    const div = document.createElement("div");
    div.classList.add("icon-muted-indicator");
    const mutedIconDiv = document.createElement("div");
    div.append(mutedIconDiv);
    mutedIconDiv.insertAdjacentHTML("beforebegin", mutedIconSrc);
    div.id = `muted-${securityId}`;
    return div;
  },

  employeeIndicatorNode(securityId: string | undefined, logo: string): HTMLDivElement {
    const div = document.createElement("div");
    div.classList.add("icon-cl-employee");
    const img = document.createElement("img");
    img.src = logo;
    div.appendChild(img);
    return div;
  },

  async getMuteIconSrc(): Promise<string> {
    const muteIcon = store.getters["IconModule/getIcon"]("mic-off");
    if (muteIcon) {
      return http.get(muteIcon.url).then((e) => {
        return e.data;
      });
    }
    return "";
  },

  getDevices(device: "audioInput" | "videoInput"): Promise<OT.Device[]> {
    return new Promise((resolve, reject) => {
      OT.getDevices((error, devices) => {
        if (devices) {
          resolve(devices.filter((item) => item.kind === device));
        } else reject(error);
      });
    });
  },

  getNameFromConnection(connectionData: string): { firstName: string; lastName: string } {
    const firstName = JSON.parse(connectionData).firstName;
    const lastName = JSON.parse(connectionData).lastname;
    return { firstName, lastName };
  },
  async getLocalMedia(): Promise<MediaStream | undefined> {
    try {
      return await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
    } catch (err) {
      console.error("OTGetUserMedia - err", err);
    }
  },
  async getMediaDeviceById(deviceId: string, options: "video" | "audio"): Promise<MediaStream> {
    const shared = { deviceId: { exact: deviceId } };
    const constraints = options == "audio" ? { audio: shared } : { video: shared };
    return await navigator.mediaDevices.getUserMedia(constraints);
  },
  setMuteIconState(securityId: string, show?: boolean): void {
    if (securityId) {
      const mutedDiv = document.getElementById(`muted-${securityId}`);
      const userNameDiv = document.getElementById(`user-name-${securityId}`);
      if (mutedDiv && userNameDiv) {
        if (show) {
          mutedDiv.style.display = "block";
          userNameDiv.style.paddingLeft = "30px";
        } else {
          mutedDiv.style.display = "none";
          userNameDiv.style.paddingLeft = "10px";
        }
      }
    }
  },
  async getDefaultDeviceId(device: "audioInput" | "videoInput"): Promise<string> {
    const devices = await this.getDevices(device);
    if (devices.length > 0) {
      return devices[0].deviceId;
    }
    return "";
  },
  userClass(securityId?: string): string {
    if (!securityId) return "";
    const idx = securityId.lastIndexOf("|");
    if (idx == -1) return securityId;
    return securityId.substring(idx + 1);
  },
  async getOrDefault(option: "audio" | "video"): Promise<string> {
    const cacheKey = option === "audio" ? LocalStorageFields.MIC_ID : LocalStorageFields.CAMERA_ID;
    const deviceKey = option === "audio" ? "audioInput" : "videoInput";
    const cacheId = localStorage.getItem(cacheKey);
    let id = await VonageUtil.getDefaultDeviceId(deviceKey);
    if (cacheId) {
      try {
        // validate cached
        await VonageUtil.getMediaDeviceById(cacheId, option);
        id = cacheId;
      } catch (err) {
        localStorage.removeItem(cacheKey);
        console.log(`Clearing stale ${option} id`);
      }
    }
    return id;
  },
};
export default VonageUtil;
