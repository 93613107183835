<template lang="pug">
#slideControls.inner-trainer-container
  .slide-controls.controls-box
    p.back-stage-header(data-name="controls-label") Controls
    .controls(v-if="currentLessonId === activeLessonId", data-name="controls")
      .control(:class="{ disabled: getSlideIndex === 0 }", @click="onNavigate(0)", data-name="icon-arrow-left-end")
        i.icon-arrow-left-end
      .control(
        :class="{ disabled: getSlideIndex === 0 && !getPrevLessonContentId }",
        @click="onNavigate(getSlideIndex - 1)",
        data-name="icon-arrow-left"
      )
        i.icon-arrow-left
      .slide-location(data-name="slide-location") {{ slideLocation }}
      .control(
        :class="{ disabled: getSlideIndex === slideCount - 1 && !getNextLessonContentId }",
        @click="onNavigate(getSlideIndex + 1)",
        data-name="icon-arrow-right"
      )
        i.icon-arrow-right
      .control(
        :class="{ disabled: getSlideIndex === slideCount - 1 }",
        @click="onNavigate(slideCount - 1)",
        data-name="icon-arrow-right-end"
      )
        i.icon-arrow-right-end
    .controls(v-else)
      Button(
        mini,
        text="Back to current lesson",
        @click="$refs.slidePreview.setLesson(activeLessonId)",
        data-name="back-to-lesson-button"
      )
  SlidePreview(ref="slidePreview", @lessonChange="onLessonChange", @slideChange="onNavigate")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SlidePreview from "./SlideNavigation.vue";
import { Lesson, Navigate, SetCourseState, ToggleShowAltModel, ToggleShowModel } from "@/types/course";
import { BaseButton as Button } from "@cruciallearning/puddle";

@Component({
  components: { Button, SlidePreview },
  computed: {
    ...mapState("CourseModule", ["activeContentId", "activeLessonId", "currentLessonId", "showModel", "showAltModel"]),
    ...mapGetters("CourseModule", ["getLesson", "getNextLessonContentId", "getPrevLessonContentId", "getSlideIndex"]),
  },
  methods: {
    ...mapMutations("CourseModule", ["setCourseState", "setCurrentLesson"]),
    ...mapActions("CourseModule", ["navigate", "toggleShowModel", "toggleShowAltModel"]),
  },
})
export default class Slides extends Vue {
  private readonly activeContentId!: string;
  private readonly getLesson!: Lesson | undefined;
  private readonly getNextLessonContentId!: string;
  private readonly getPrevLessonContentId!: string;
  private readonly getSlideIndex!: number;
  private readonly showModel!: boolean;
  private readonly showAltModel!: boolean;
  private readonly navigate!: Navigate;
  private readonly setCourseState!: SetCourseState;
  private readonly toggleShowModel!: ToggleShowModel;
  private readonly toggleShowAltModel!: ToggleShowAltModel;

  destroyed(): void {
    document.body.removeEventListener("keydown", this.onKeyDown);
  }

  mounted(): void {
    document.body.addEventListener("keydown", this.onKeyDown);
  }

  updated(): void {
    this.$nextTick(() => {
      const target = document.getElementById(this.activeContentId);
      if (target) {
        if (target.getBoundingClientRect().bottom > window.innerHeight) target.scrollIntoView(false);
        if (target.getBoundingClientRect().top < 312) target.scrollIntoView();
      }
    });
  }

  get slideLocation(): string {
    if (!this.getLesson) return "Lesson Loading";
    return `${this.getSlideIndex + 1} / ${this.getLesson.slides[this.slideCount - 1].index}`;
  }

  get slideCount(): number {
    return this.getLesson?.slides.length || 0;
  }

  onLessonChange(title: string): void {
    this.setCourseState({ currentLessonId: title });
  }

  async onKeyDown(event: KeyboardEvent): Promise<void> {
    const forwardKeys = ["Space", "ArrowRight", "PageDown", "ArrowDown", "Enter"];
    const reverseKeys = ["ArrowLeft", "PageUp", "ArrowUp"];

    if (document.activeElement?.tagName === "BODY") {
      if (forwardKeys.indexOf(event.code) != -1) {
        event.preventDefault();
        await this.onNavigate(this.getSlideIndex + 1);
      } else if (reverseKeys.indexOf(event.code) != -1) {
        event.preventDefault();
        await this.onNavigate(this.getSlideIndex - 1);
      }
    }
  }

  async onNavigate(slideIndex: number): Promise<void> {
    if (this.showModel) {
      this.toggleShowModel();
    }
    if (this.showAltModel) {
      this.toggleShowAltModel();
    }
    if (slideIndex < 0 && this.getPrevLessonContentId) {
      this.navigate(this.getPrevLessonContentId);
    } else if (slideIndex > this.slideCount - 1 && this.getNextLessonContentId) {
      this.navigate(this.getNextLessonContentId);
    } else {
      const index = this.getLesson?.slides[slideIndex].index;
      this.navigate(`${this.getLesson?.title} ${index}`);
    }
  }
}
</script>

<style lang="scss" scoped>
.controls-box {
  height: 80px;
}
.button {
  padding: 12px 11px !important;
}
</style>
