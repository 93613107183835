export interface GridState {
  adminCols: number;
  usersCols: number;
  chatCols: number;
  resizingOption: ResizingOption;
}

export interface GridGetters {
  isResizing: boolean;
  adminCenterWidth: number;
  learnerCenterWidth: number;
  adminStart: number;
  adminEnd: number;
  usersStart: number;
  usersEnd: number;
  chatStart: number;
  chatEnd: number;
  validAdminIndex: boolean;
  validUsersIndex: boolean;
  validChatIndex: boolean;
}

export enum ResizingOption {
  ADMIN = "admin",
  USERS = "users",
  CHAT = "chat",
  NONE = "none",
}

export interface SetGridState {
  (changes: Partial<GridState>): void;
}
