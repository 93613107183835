<template lang="pug">
#video(:class="{ pointer: $auth.ADMIN }")
  #main_video.videoPlayer(:class="videoClasses", @mousedown="mouseDown", @mouseup="mouseUp")
  .close(v-if="$auth.ADMIN")
    SquareButton(icon="x", color="black", @click="applyOverlay()")
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { LocalStorageFields } from "@/types/base";
import store from "@/store";
import { EventContentModel } from "@cruciallearning/puddle";
import { MediaUpdateType, Mute, UserModel } from "@/types";
import {
  CloseOverlay,
  EndVideo,
  PauseVideoBroadcast,
  PlayVideoBroadcast,
  ResetVideo,
  SetVideoState,
  VideoConfig,
  VideoControls,
  VideoStates,
} from "@/types/video";
import { Video } from "@/types/course";
import SquareButton from "@/components/widgets/SquareButton.vue";
import { MessageType, SendWindowMessage } from "@/types/browser";

@Component({
  components: { SquareButton },
  computed: {
    ...mapState("VideoModule", ["firstActionUserId", "lastActionUserId", "playing", "position", "processingUpdate"]),
    ...mapGetters("UsersModule", ["getSelf"]),
    ...mapState("EventModule", ["event"]),
    ...mapState("VonageModule", ["publisher"]),
  },
  methods: {
    ...mapActions("UsersModule", ["mute"]),
    ...mapActions("VideoModule", [
      "endVideo",
      "playVideoBroadcast",
      "pauseVideoBroadcast",
      "closeOverlay",
      "resetVideo",
    ]),
    ...mapActions("BrowserModule", ["messageSecondDisplay"]),
    ...mapMutations("VideoModule", ["setVideoState"]),
  },
})
export default class BaseVideo extends Vue {
  @Prop({ required: true }) readonly content!: Video;

  private readonly firstActionUserId!: string;
  private readonly getSelf!: UserModel;
  private readonly lastActionUserId!: string;
  private readonly playing!: boolean;
  private readonly position!: number;
  private readonly processingUpdate!: boolean;
  private readonly event!: EventContentModel;
  private readonly closeOverlay!: CloseOverlay;
  private readonly endVideo!: EndVideo;
  private readonly mute!: Mute;
  private readonly pauseVideoBroadcast!: PauseVideoBroadcast;
  private readonly playVideoBroadcast!: PlayVideoBroadcast;
  private readonly resetVideo!: ResetVideo;
  private readonly messageSecondDisplay!: SendWindowMessage;
  private readonly setVideoState!: SetVideoState;
  private readonly publisher!: OT.Publisher | null;

  private video?: VideoControls;
  private videoClasses = "";
  private videoConfig?: VideoConfig;
  private videoScript?: string;
  private wistiaScript = "https://fast.wistia.com/assets/external/E-v1.js";
  private scrubbing = false;
  async created(): Promise<void> {
    const captions = localStorage.getItem(LocalStorageFields.CAPTIONS);
    if (captions) {
      store.commit("setRootState", { captions });
    }
    window._wq = window._wq || [];
    this.videoConfig = {
      id: this.content.providerId,
      options: {
        videoFoam: true,
        wmode: "transparent",
        volumeControl: true,
        fullscreenButton: false,
        smallPlayButton: this.showControls,
        autoPlay: this.$auth.ADMIN,
        controlsVisibleOnLoad: this.showControls,
        playbar: this.showControls,
        copyLinkAndThumbnailEnabled: false,
        playButton: true,
        plugin: {
          "captions-v1": {
            transcript: false,
            onByDefault: store.state.captions,
          },
        },
      },
      onReady: (video: VideoControls): void => {
        this.video = video;
        if (this.playing) {
          if (this.position > 0) {
            this.video?.time(this.position);
          }
          this.video?.play();
        }
        if (this.$auth.ADMIN) {
          video?.bind("play", () => {
            this.messageSecondDisplay({ type: MessageType.PLAY, content: `${this.video?.time()}` });
            this.turnOffMic();
            if (!this.playing && this.video?.state() === VideoStates.PLAYING) {
              this.playVideoBroadcast(this.video?.time() as number);
            }
            this.setVideoState({ processingUpdate: false });
          });
          video?.bind("pause", () => {
            this.messageSecondDisplay({ type: MessageType.PAUSE });
            if (this.playing && this.video?.state() !== VideoStates.PLAYING) {
              this.pauseVideoBroadcast();
            }
          });
          video?.bind("seek", (currentTime) => {
            this.messageSecondDisplay({ type: MessageType.SCRUB, content: `${currentTime}` });
            if (!this.processingUpdate && this.video?.state() === VideoStates.PLAYING) {
              this.playVideoBroadcast(currentTime as number);
            }
            this.setVideoState({ processingUpdate: false });
          });
        }
        video?.bind("end", () => this.endVideo());
        video?.bind("captionschange", (t: { visible: boolean }) => {
          localStorage.setItem(LocalStorageFields.CAPTIONS, `${t.visible}`);
          if (this.videoConfig?.options.plugin["captions-v1"].onByDefault != undefined)
            this.videoConfig.options.plugin["captions-v1"].onByDefault = t.visible;
        });
      },
    };
    window._wq.push(this.videoConfig);
    this.videoScript = `https://fast.wistia.com/embed/medias/${this.content.providerId}.jsonp`;
    await this.$loadscript.load(this.videoScript);
    await this.$loadscript.load(this.wistiaScript);
    this.videoClasses = `wistia_embed wistia_async_${this.content.providerId}`;
    if (!this.$auth.ADMIN) {
      document.getElementById("video")?.classList.add("pointer-event");
    }
  }

  mounted(): void {
    if (this.$auth.ADMIN) {
      document.body.addEventListener("keydown", this.onKeyDown);
    }
  }

  beforeDestroy(): void {
    window._wq.push({ revoke: this.videoConfig });
  }
  async destroyed(): Promise<void> {
    this.video?.remove();
    await this.$loadscript.unload(this.videoScript as string).catch(() => undefined);
    await this.$loadscript.unload(this.wistiaScript).catch(() => undefined);
    this.video = void 0;
    this.videoConfig = void 0;
    this.videoScript = void 0;
    this.turnOnMic(true);
    this.resetVideo();
    if (this.$auth.ADMIN) {
      document.body.removeEventListener("keydown", this.onKeyDown);
    }
  }

  get showControls(): boolean {
    return this.$auth.ADMIN || this.event.systemCheck;
  }

  onKeyDown(event: KeyboardEvent): void {
    const toggleKeys = ["F5", "Escape"];

    if (document.activeElement?.tagName === "BODY") {
      if (toggleKeys.indexOf(event.code) != -1) {
        if (this.video?.state() === VideoStates.PLAYING) {
          this.pauseVideoBroadcast();
        } else if (this.video?.state() === VideoStates.ENDED) {
          this.playVideoBroadcast(0);
        } else {
          this.playVideoBroadcast(this.video?.time() as number);
        }
        event.preventDefault();
      }
    }
  }

  @Watch("playing")
  onVideoStatus(): void {
    if (this.playing) this.turnOffMic();
    else {
      this.turnOnMic(this.video?.state() === VideoStates.ENDED);
      this.video?.pause();
    }
  }

  @Watch("position")
  onPositionChange(): void {
    if (this.getSelf.securityId === this.lastActionUserId) return;
    if (this.playing) {
      this.video?.time(this.position);
      this.video?.play();
    }
  }

  turnOffMic(): void {
    if (this.$auth.ADMIN) this.mute({ type: MediaUpdateType.ALL });
  }

  turnOnMic(videoEnded = false): void {
    if (this.$auth.ADMIN) {
      if (videoEnded) {
        if (this.getSelf.securityId === this.firstActionUserId) {
          this.publisher?.publishAudio(true);
        }
      } else if (this.getSelf.securityId === this.lastActionUserId) {
        this.publisher?.publishAudio(true);
      }
    }
  }

  applyOverlay(): void {
    this.pauseVideoBroadcast();
    this.resetVideo();
    this.closeOverlay();
  }
  mouseDown(): void {
    document.addEventListener("mousemove", this.mouseMove);
  }
  mouseUp(): void {
    // update with final scrub location
    document.removeEventListener("mousemove", this.mouseMove);
    if (this.scrubbing) {
      if (this.video?.state() === VideoStates.PLAYING) {
        this.playVideoBroadcast(this.video?.time() as number);
      }
    }
    this.scrubbing = false;
  }
  mouseMove(): void {
    this.scrubbing = true;
  }
}
</script>
<style scoped lang="scss">
#video {
  position: relative;
  width: 100%;
  margin: auto;
}
button i {
  margin-right: 0;
}

.close {
  position: absolute;
  padding-right: 8px;
  top: 0;
  right: 0;
  padding-top: 8px;
}

.videoPlayer {
  height: 100%;
  padding: 0;
  width: 100%;
}
.pointer-event {
  pointer-events: none;
}
.close > div {
  background-color: white;
}
</style>
