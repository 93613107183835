import { sockets } from "./services/socket_api";
import { pulse } from "./services/pulse";
import { visible } from "@/directives";
import api from "@/services/api";
import { default as loadscript } from "@cruciallearning/puddle/loadscript";
import socket from "@/services/socket";
import App from "@/App.vue";
import * as utils from "@/utils";
import Vue from "vue";

import { Auth0Plugin, getInstance } from "@cruciallearning/puddle/auth";

//--> Importing CSS for webpacking
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.dark.compact.css";
import "@/assets/css/style.css";
import "@/assets/css/vapor.scss";
import "@/assets/css/dx.global.overrides.scss";
import "simplebar/dist/simplebar.min.css";
import store from "./store";
import router from "./router";
import { EventContentModel } from "@cruciallearning/puddle";
import VonageUtil from "./components/Vonage/vonageUtil";
import authorization from "./auth/authorization";
import { LocalStorageFields } from "./types/base";
import config from "@/config";
import AppUnauthorized from "./AppUnauthorized.vue";
import { http } from "./http";

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$loadscript = loadscript;
Vue.prototype.$socket = socket;
Vue.prototype.$sockets = sockets;
Vue.prototype.$pulse = pulse;
Vue.prototype.$utils = utils;
Vue.directive("visible", visible.bind);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const eventId = urlParams.get("eventId");
if (eventId) {
  localStorage.setItem("CL-LearningEventId", eventId);
}

const authorizationCheck = (event?: EventContentModel) => {
  if (event && !authorization.isAuthorized(event)) {
    console.error("Authorization for secured environent failed");
    throw new Error("authorization failure");
  }
};

const loadCookieScripts = async () => {
  await loadscript.load("googleConsentMode.js");
  await loadscript.load(config.osanoUrl);
  await loadscript.load("googleTagManager.js");
};

const postInit = async () => {
  const isBlurred = localStorage.getItem(LocalStorageFields.BACKGROUND_BLUR) === "true";
  store.commit("MediaModule/setMediaState", { isBlurred });

  const selectedVideoId = await VonageUtil.getOrDefault("video");
  store.commit("setRootState", { selectedVideoId });

  const selectedAudioId = await VonageUtil.getOrDefault("audio");
  store.commit("setRootState", { selectedAudioId });

  const adminSidebarCols = localStorage.getItem(LocalStorageFields.ADMIN_COLS);
  if (adminSidebarCols) {
    store.commit("GridModule/setGridState", { adminCols: Number(adminSidebarCols) });
  }
  Object.defineProperty(getInstance(), "ADMIN", {
    get(): boolean {
      return authorization.isManager() || authorization.isApprovedObserver();
    },
  });
};

Vue.use(Auth0Plugin, {
  oktaDomain: config.oktaDomain,
  backendUrl: config.backendUrl,
  clientId: config.oktaClientId,
  onRedirectCallback: () => console.log("Redirect CB"),
});

getInstance().$watch("authUser.token", async (token: string) => {
  loadCookieScripts();
  let error = false;
  try {
    http.interceptors.request.use(
      (request) => {
        if (!request.headers["Authorization"]) request.headers["Authorization"] = `Bearer ${token}`;
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const auth = getInstance();
    const authUser = auth.authUser;
    const eventId = localStorage.getItem("CL-LearningEventId") || "";
    const event = await api.event.getEvent(eventId);
    store.commit("EventModule/setEventState", { event });
    if (!event) {
      throw new Error("Event Not found");
    }
    if (event && event.expired) {
      throw new Error("event expired");
    }
    await postInit();

    authorizationCheck(event);
    await api.course.getCourse(eventId);
    await api.icon.loadGlobal();
    if (event.practice) {
      store.commit("setRootState", { practiceConfirm: true });
    }
    const skipSocket = window.opener != null;
    if (authUser.token && !skipSocket) {
      socket.connect(authUser.token);
    }
    if (event.systemCheck) {
      router.replace("/system-check").catch(() => undefined);
    }
  } catch (err) {
    error = true;
    console.error("Virtual init error", err);
  }
  new Vue({
    store,
    router,
    render: (h) => h(!error ? App : AppUnauthorized),
  }).$mount("#app");
});
