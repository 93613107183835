import { ConfigVars, EnvVars } from "@/types";
import ConfigFile from "../../config.json";

const config = ConfigFile as ConfigVars;

const envVars: EnvVars = {
  backendUrl: config.BACKEND_URL,
  cdnUrl: config.CDN_URL,
  env: config.ENV,
  frostUrl: config.FROST_URL,
  securityClientId: config.SECURITY_CLIENT_ID,
  securityPassword: config.SECURITY_PASSWORD,
  securityRealm: config.SECURITY_CLIENT_REALM,
  securityUsername: config.SECURITY_USERNAME,
  socketUrl: config.SOCKET_URL,
  streamKey: config.STREAM_KEY,
  vaporUrl: config.VAPOR_URL,
  oktaClientId: config.OKTA_CLIENT_ID,
  oktaDomain: config.OKTA_DOMAIN,
  osanoUrl: config.OSANO_URL,
};

const showError: (x: string) => void = (varName: string): void => {
  console.error(`Missing ${varName} environment variable`);
};
if (!envVars.backendUrl) showError("BACKEND_URL");
if (!envVars.env) showError("ENV");
if (!envVars.cdnUrl) showError("CDN_URL");
if (!envVars.frostUrl) showError("FROST_URL");
if (!envVars.securityClientId) showError("SECURITY_CLIENT_ID");
if (!envVars.securityPassword) showError("SECURITY_PASSWORD");
if (!envVars.securityRealm) showError("SECURITY_REALM");
if (!envVars.securityUsername) showError("SECURITY_USERNAME");
if (!envVars.socketUrl) showError("SOCKET_URL");
if (!envVars.streamKey) showError("STREAM_KEY");
if (!envVars.vaporUrl) showError("VAPOR_URL");

export default envVars;
