<template lang="pug">
.message(:class="{ self: content.self }", @mouseover="mouseOver", @mouseleave="mouseLeave")
  Unpinner(
    v-if="showConfirmUnpin",
    :elementId="actionPanelId",
    @keep="showConfirmUnpin = false",
    @unpin="unpinConfirmed"
  )
  .avatar
    Avatar(:firstName="content.firstName", :lastName="content.lastName")
  .message-content
    p.meta(data-name="user-name") {{ `${content.firstName} ${content.lastName}` }}
    p
      time(data-name="message-timestamp") {{ formatChatTime(content.time) }}
    p(v-html="content.message", data-name="message-content")
  .message-action-btn-container(:id="actionPanelId") 
    Icon.message-action-icon(
      v-if="$auth.ADMIN && !fromPinnedOverlay",
      value="x-circle",
      :dataName="`delete-chat-${content.id}`",
      :id="`delete-chat-${content.id}`",
      height="0.8rem",
      width="0.8rem",
      :class="[!showActionIcons ? 'hidden-action-icons' : '']",
      @click="clickDeleteMessage",
      :data-name="`delete-chat-${content.id}`"
    )
    template(v-if="showPin")
      Icon.message-action-icon(
        v-if="!fromPinnedOverlay",
        value="Pin a Chat",
        :dataName="pinIconId",
        :id="pinIconId",
        height="0.8rem",
        width="0.8rem",
        :class="[!showActionIcons ? 'hidden-action-icons' : '']",
        @click="clickPinMessage",
        :data-name="`pin-chat-${content.id}`"
      )
      Icon.message-action-icon-pinned(
        v-else,
        :value="pinnedIconValue",
        :dataName="pinIconId",
        :id="pinIconId",
        height="0.8rem",
        width="0.8rem",
        :class="[!showActionIcons ? 'hidden-action-icons' : '']",
        @click="clickPinMessage",
        @mouseover="isPinHovered = true",
        @mouseleave="isPinHovered = false"
      )
</template>

<script lang="ts">
import { Icon } from "@cruciallearning/puddle/components";
import { ChatModel, ChatType, DeleteMessage, PinMessage, PinnedChatModel, UnpinMessage } from "@/types";
import Avatar from "@/components/Sidebar/Avatar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import Unpinner from "./Unpinner.vue";

@Component({
  components: { Icon, Avatar, Unpinner },
  methods: {
    ...mapActions("ChatModule", ["deleteMessage", "pinMessage", "unpinMessage"]),
  },
  computed: {
    ...mapState("ChatModule", ["pinned"]),
  },
})
export default class Message extends Vue {
  @Prop({ required: false }) readonly content!: ChatModel | PinnedChatModel;
  @Prop({ required: false, default: false }) readonly type!: ChatType;
  @Prop({ required: false, default: false }) readonly fromPinnedOverlay!: boolean;

  private readonly deleteMessage!: DeleteMessage;
  private readonly pinMessage!: PinMessage;
  private readonly unpinMessage!: UnpinMessage;
  private readonly pinned!: PinnedChatModel[];

  showActionIcons = false;
  showConfirmUnpin = false;
  isPinHovered = false;
  private styleString = "";

  get allowDelete(): boolean {
    if (this.type === ChatType.MAIN || this.type === ChatType.LOBBY) return true;
    return false;
  }

  get showPin(): boolean {
    return this.$auth.ADMIN && this.type === ChatType.MAIN;
  }

  get pinnedIconValue(): string {
    return this.isPinHovered ? "Pin a Chat Out" : "Pin a Chat In";
  }

  get actionPanelId(): string {
    return `action-panel-${this.content.id}`;
  }

  get pinIconId(): string {
    return `Pin-icon-${this.content?.id}`;
  }

  createStyle(first: string, last: string): string {
    const red = ((first.toUpperCase().charCodeAt(0) - 65) / 26) * 255;
    const green = ((last.toUpperCase().charCodeAt(0) - 65) / 26) * 255;
    const blue = ((last.toUpperCase().charCodeAt(last.length - 1) - 65) / 26) * 255;
    const rgb = `rgb(${red},${green},${blue})`;
    const darkText = red * 0.299 + green * 0.587 + blue * 0.114 > 125;
    const text = darkText ? "#000000" : "#ffffff";
    return `background-color:${rgb};color:${text}`;
  }
  mouseOver(): void {
    if (this.$auth.ADMIN && this.allowDelete) {
      this.showActionIcons = true;
    }
  }
  mouseLeave() {
    this.showActionIcons = false;
  }

  clickDeleteMessage(): void {
    if (this.isPinned) {
      this.unpinMessage({ type: this.type, messageId: this.messageId });
    }
    this.deleteMessage({ type: this.type, messageId: this.messageId });
  }

  clickPinMessage(): void {
    if (this.isPinned) {
      this.showConfirmUnpin = true;
    } else {
      this.pinMessage({ type: this.type, messageId: this.messageId });
    }
  }

  get messageId(): string {
    return this.fromPinnedOverlay ? (this.content as PinnedChatModel).sourceId : this.content.id;
  }

  get isPinned(): boolean {
    return this.pinned.findIndex((e) => e.sourceId === this.messageId) != -1;
  }

  unpinConfirmed(): void {
    this.showConfirmUnpin = false;
    this.unpinMessage({ type: this.type, messageId: this.messageId });
  }
  formatChatTime(contentTime: string): string {
    if (contentTime.includes("AM")) contentTime = contentTime.replace("AM", "a.m.");
    if (contentTime.includes("PM")) contentTime = contentTime.replace("PM", "p.m.");
    return contentTime;
  }
}
</script>
<style lang="css" scoped>
::v-deep .message-action-icon-pinned,
::v-deep .message-action-icon {
  margin-top: 0.2rem;
}

::v-deep .message-action-icon:hover > svg > path,
::v-deep .message-action-icon:hover > svg > g > path {
  fill: var(--gray-40);
}
::v-deep .message-action-icon-pinned > svg > path,
::v-deep .message-action-icon > svg > path,
::v-deep .message-action-icon > svg > g > path {
  fill: white;
}
.message-action-btn-container {
  display: flex;
  flex-direction: column;
}
.hidden-action-icons {
  visibility: hidden;
}
.meta {
  text-transform: capitalize;
}
</style>
