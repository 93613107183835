import routes from "@/services/routes";
import store from "@/store";
import { ChatModel, RootState } from "@/types";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { sockets } from "@/services/socket_api";
import MessageEncoder from "./MessageEncoder";
import { http } from "@/http";
import { AxiosProgressEvent } from "axios";
export interface DownloadModel {
  fileName: string;
  pdfBytes: string;
}
const Download = {
  saveChatList(messages: Partial<ChatModel>[], downloaded = false): void {
    let dataString = "";
    const title = (store.state as RootState).EventModule?.event.title;
    const referenceNumber = (store.state as RootState).EventModule?.event.referenceNumber;
    if (messages) {
      if (messages.length > 98 && !downloaded) {
        // download full log
        sockets.chat.downloadChatLogSend();
        return;
      }

      let sessionId: string | undefined = undefined;
      const sorted = messages.sort((a, b) => {
        if (a.sent && b.sent) {
          return new Date(b.sent).getTime() - new Date(a.sent).getTime();
        }
        return 0;
      });
      sorted.forEach((message) => {
        if (sessionId !== message.sessionId) {
          if (sessionId) dataString += "-----------------------------------\n";
          sessionId = message.sessionId;
        }
        if (message.sent) {
          const time = new Date(message.sent);
          const timeString = `${time.getHours()}:${this.pad(time.getMinutes(), 2)}:${this.pad(time.getSeconds(), 2)}`;
          const decoded = `${MessageEncoder.decode(message.message)}`;
          dataString += `${timeString} From ${message.firstName} ${message.lastName}: ${decoded}\n`;
        }
      });
    }
    const data = new Blob([dataString], { type: "text/plain" });
    let namestring = `Chat-`;
    if (title) namestring += `${title}-`;
    if (referenceNumber) namestring += `${referenceNumber}-`;
    namestring += `${new Date().toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })}`;
    const filename = namestring.replace(/\s/g, "");
    const url = window.URL.createObjectURL(data);
    saveAs(url, filename);
    window.URL.revokeObjectURL(url);
    store.dispatch("update", { downloadingChat: false });
  },
  pad(value: number, digits: number): string {
    let result = "" + value;
    while (result.length < digits) result = "0" + result;
    return result;
  },
  async downloadLearnerGuide(save = true): Promise<{ fileSize: number }> {
    store.commit("LoaderModule/setLoaderState", {
      message: "Customizing learner guide",
      completed: 0,
      showLoader: true,
    });
    let fileSize = 0;
    const onDownloadProgress = (progressEvent: AxiosProgressEvent): void => {
      fileSize = progressEvent.total || 0;
      const percentCompleted = Math.round((progressEvent.loaded * 100) / fileSize);
      store.commit("LoaderModule/setLoaderState", {
        completed: percentCompleted,
      });
    };
    const learningEventId = store.state.trainingEventId;
    const date = DateTime.now().toISO({ includeOffset: false });
    await http
      .post<DownloadModel>(`${routes.DOWNLOADS}`, { learningEventId, date }, { timeout: 300000, onDownloadProgress })
      .then((response) => {
        try {
          const binary = atob(response.data.pdfBytes.replace(/\s/g, ""));
          const len = binary.length;
          const buffer = new ArrayBuffer(len);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
          }
          const objectURL = window.URL.createObjectURL(new Blob([view], { type: "application/pdf" }));
          if (save) saveAs(objectURL, `${response.data.fileName}.pdf`);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error("Error occurred downloading personalized pdf");
        } finally {
          store.commit("LoaderModule/setLoaderState", {
            message: "",
            completed: 0,
            showLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error downloading learner guide " + error);
      });
    return { fileSize };
  },
};
export default Download;
