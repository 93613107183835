import { RootState } from "@/types";
import { MediaState } from "@/types/media";
import { Module } from "vuex";

const MediaModule: Module<MediaState, RootState> = {
  namespaced: true,
  state(): MediaState {
    return {
      hasAudio: false,
      hasVideo: false,
      isBlurred: false,
    };
  },
  mutations: {
    setMediaState(state: MediaState, options: Partial<MediaState>): void {
      Object.assign(state, options);
    },
  },
};
export default MediaModule;
