<template lang="pug">
div
  .display-options(v-if="showDisplayOptions")
    BaseSwitch(switchedOffText="3", switchedOnText="2", :value="twoColumn", @valueChanged="columnSwitchChange")
  .video-container(:id="elementId")
</template>
<script lang="ts">
import { Group, UserModel } from "@/types";
import { ReactionUserModel } from "@/types/reaction";
import { ReactionType, StatusModel, StatusOptions } from "@/types/status";
import { VideoContainerId } from "@/types/vonage";
import { BaseSwitch } from "@cruciallearning/puddle";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import util from "./VideoListUtil";
import VonageUtil from "@/components/Vonage/vonageUtil";
import authorization from "@/auth/authorization";

@Component({
  components: { BaseSwitch },
  computed: {
    ...mapState(["activeSpeakerId"]),
    ...mapState("UsersModule", ["statuses", "videoUsers"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapGetters("UsersModule", ["getById", "getSelf"]),
    ...mapState("VonageModule", ["subscribers", "publisher"]),
  },
})
export default class AttendeeVideos extends Vue {
  @Prop({ required: true, type: String }) videoElementId!: VideoContainerId;
  @Prop({ required: true }) readonly users!: UserModel[];

  private readonly subscribers!: OT.Subscriber[];
  private readonly publisher!: OT.Publisher | null;

  private readonly statuses!: ReactionUserModel[];
  private readonly videoUsers!: string[];
  private readonly activeSpeakerId!: string;
  private readonly getActiveBreakoutSelf!: Group | undefined;
  private readonly getSelf!: UserModel;
  private twoColumn = true;

  get elementId(): string {
    return `${this.videoElementId}-video-container`;
  }

  get showDisplayOptions(): boolean {
    return this.videoElementId === VideoContainerId.LEARNERS && !this.getActiveBreakoutSelf;
  }

  mounted(): void {
    this.handleResize();
  }

  @Watch("users")
  handleResize(): void {
    const query = `#${this.elementId} > .OT_root`;
    const videos = document.querySelectorAll(query);
    videos.forEach((el) => this.setToggledProperties(el as HTMLElement));
  }

  @Watch("videoUsers")
  reorderVideos(firstRun = true): void {
    const priority = this.statuses.filter((e) => e.status === ReactionType.RAISE_HAND).map((e) => e.securityId);
    const nonPriority = this.videoUsers.filter((e) => priority.findIndex((i) => i == e) == -1);
    const selfSecurityId = this.$auth.authUser.id;
    const selfVidIdx = nonPriority.findIndex((e) => e === selfSecurityId);
    //self is default centered if hand is not raised
    if (selfVidIdx != -1) {
      nonPriority.splice(selfVidIdx, 1);
      const securityId = VonageUtil.userClass(selfSecurityId);
      const query = `#${this.elementId} > .user-video-${securityId}`;
      const userVideo = document.querySelector(query);
      if (userVideo) {
        util.resetOrderStyle(0, userVideo as HTMLElement);
      }
    }
    let order = -30;
    priority.forEach((userId) => {
      this.findAndOrder(userId, order++);
    });
    order = 1;
    nonPriority.forEach((userId) => {
      this.findAndOrder(userId, order++);
    });
    if (firstRun) {
      this.reactionUpdate();
      this.handleResize();
    }
  }

  @Watch("statuses")
  reactionUpdate(): void {
    if (this.users && this.statuses) {
      const clearable = this.statuses.filter((e) => e.status !== ReactionType.AWAY);
      if (clearable.length == 0) {
        this.clearAllReactions();
      }
      this.statuses.forEach((reaction) => {
        const securityId = VonageUtil.userClass(reaction.securityId);
        const query = `#${this.elementId} > .user-video-${securityId}`;
        const userVideo = document.querySelector(query);
        if (userVideo) {
          const reactionModel = StatusOptions.find((item) => reaction.status === item.display);
          this.updateReactionStyles(userVideo as HTMLElement, reactionModel);
        }
      });
    }
    this.reorderVideos(false);
  }

  @Watch("activeSpeakerId")
  activeSpeakerUpdate() {
    const query = `#${this.elementId} > .user-video`;
    const videos = document.querySelectorAll(query);
    if (videos) {
      videos.forEach((el) => el?.classList.remove("active-speaker-video"));
    }
    const securityId = VonageUtil.userClass(this.activeSpeakerId);
    const activeQuery = `#${this.elementId} > .user-video-${securityId}`;
    const activeVideo = document.querySelector(activeQuery);
    if (activeVideo) {
      activeVideo.classList.add("active-speaker-video");
    }
  }

  columnSwitchChange(event: { value: boolean }): void {
    this.twoColumn = event.value;
    this.handleResize();
  }

  isVideoActive(securityId: string): boolean {
    if (this.subscribers) {
      const stream = this.subscribers.find((e) => {
        const data = e.stream?.connection.data;
        if (data) {
          return securityId === JSON.parse(data).securityId;
        }
        return false;
      })?.stream;
      if (stream) return stream.hasVideo;
    }
    return false;
  }

  getActiveVideoCount(videoElementId: VideoContainerId): number {
    let videoCount = this.publisher?.stream?.hasVideo ? 1 : 0;

    if (videoCount) {
      const securityId = this.getSelf.securityId;
      if (videoElementId == VideoContainerId.TRAINERS && !authorization.isTrainer(securityId)) videoCount = 0;
      if (videoElementId == VideoContainerId.MODERATORS && !authorization.isModerator(securityId)) videoCount = 0;
    }

    const activeUsers = this.users.filter((user) => this.isVideoActive(user.securityId));
    if (activeUsers) videoCount += activeUsers.length;
    return videoCount;
  }

  getVideoDimensions(): { paddingBottom: string; width: string; margin?: string } {
    const adminElement =
      this.videoElementId == VideoContainerId.TRAINERS || this.videoElementId == VideoContainerId.MODERATORS;
    if (adminElement) {
      return { paddingBottom: "112px", width: "112px", margin: "8px" };
    }
    if (this.twoColumn) {
      return { paddingBottom: "45%", width: "45%" };
    }
    return { paddingBottom: "30%", width: "30%" };
  }

  setToggledProperties(element: HTMLElement): void {
    const dimensions = this.getVideoDimensions();
    element.style.width = dimensions.width;
    element.style.height = "0";
    element.style.paddingBottom = dimensions.paddingBottom;
    element.style.margin = dimensions.margin || "";
  }

  updateReactionStyles(element: HTMLElement, status: StatusModel | undefined): void {
    if (element) {
      element.classList.forEach((c: string) => {
        if (c.startsWith("reaction-")) {
          element.classList.remove(c);
        }
      });
      if (!status) {
        element?.classList.remove("reaction");
      } else {
        element?.classList.add("reaction", status.cssClass);
      }
    }
  }

  clearAllReactions(): void {
    const query = `#${this.elementId} > .OT_root`;
    const videos = document.querySelectorAll(query);
    videos.forEach((el) => {
      el.classList.forEach((c: string) => {
        if (c.includes("user-video-")) {
          const securityId = c.replace("user-video-", "");
          const status = this.statuses.find((e) => e.securityId === securityId && e.status === ReactionType.AWAY);
          if (!status) {
            util.clearAllUserReactions(el as HTMLElement);
          }
        }
      });
    });
  }

  findAndOrder(userId: string, order: number): void {
    const securityId = VonageUtil.userClass(userId);
    const query = `#${this.elementId} > .user-video-${securityId}`;
    const userVideo = document.querySelector(query);
    if (userVideo) {
      util.resetOrderStyle(order, userVideo as HTMLElement);
    }
  }
}
</script>
<style lang="scss" scoped>
.video-container {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
}
::v-deep .user-video {
  margin: 1.25%;
}
::v-deep .OT_audio-only {
  display: none;
}
.display-options {
  float: right;
  margin-right: 0.5rem;
}
</style>
