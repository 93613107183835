<template lang="pug">
div(style="display: none")
</template>
<script lang="ts">
import store from "@/store";
import {
  AudioLevelUpdatedEvent,
  Group,
  StreamCreatedEvent,
  StreamDestroyedEvent,
  VideoElementCreatedEvent,
} from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import OT from "@opentok/client";
import authorization from "@/auth/authorization";
import { mapActions, mapGetters, mapState } from "vuex";
import { sockets } from "@/services/socket_api";
import VonageUtil from "./vonageUtil";
import { ApplyUserVideoFilter, SetPublisher, VideoContainerId } from "@/types/vonage";
import Authorization from "@/auth/authorization";
import employeeLogo from "@/assets/images/logos/Crucial-C.svg";
import { EventContentModel } from "@cruciallearning/puddle";
import { genericErrors, publishErrors } from "./ErrorHandler";
import log from "./DebugLogger";

@Component({
  computed: {
    ...mapState(["selectedAudioId", "selectedVideoId"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapState("EventModule", ["event"]),
    ...mapState("MediaModule", ["hasAudio", "hasVideo", "isBlurred"]),
    ...mapState("VonageModule", ["session"]),
  },
  methods: {
    ...mapActions("VonageModule", ["setPublisher", "applyUserVideoFilter"]),
  },
})
export default class Publisher extends Vue {
  @Prop({ required: false, default: false }) isBreakout!: boolean;
  @Prop({ required: false, default: false }) isSysCheck!: boolean;
  @Prop({ required: true }) token!: string;
  private muteIconSrc!: string;
  private readonly event!: EventContentModel;
  private readonly getActiveBreakoutSelf!: Group | undefined;

  private readonly hasAudio!: boolean;
  private readonly hasVideo!: boolean;
  private readonly isBlurred!: boolean;

  private readonly selectedAudioId!: string;
  private readonly selectedVideoId!: string;

  private readonly session!: OT.Session | null;

  private setPublisher!: SetPublisher;
  private applyUserVideoFilter!: ApplyUserVideoFilter;

  private audioLevel = 0;
  mounted(): void {
    this.sessionUpdate();
  }
  init(
    insertMode: "append" | "replace" | "after" | "before" | undefined = undefined,
    targetElement: string | undefined = void 0,
    insertDefaultUI = false,
    showControls = false
  ): OT.Publisher {
    const options: OT.PublisherProperties = {
      insertDefaultUI,
      insertMode,
      name: this.$auth.authUser.id,
      fitMode: "cover",
      enableDtx: true,
      publishVideo: false,
      publishAudio: false,
      scalableVideo: false,
      showControls,
      echoCancellation: true,
      noiseSuppression: true,
      audioSource: this.selectedAudioId || undefined,
      videoSource: this.selectedVideoId || undefined,
      mirror: true,
    };
    const _publisher = OT.initPublisher(targetElement, options, genericErrors);
    _publisher?.on({
      videoElementCreated: (event: VideoElementCreatedEvent) => {
        log("Publisher Video Element created ", event);
        if (event.target.element) {
          const securityId = VonageUtil.userClass(this.$auth.authUser.id);
          event.target.element.classList.add("user-video", "speaker", `user-video-${securityId}`);
        }
        if (!this.isBreakout) {
          if (event.target.element) {
            event.target.element.style.width = "45%";
            event.target.element.style.height = "0";
            event.target.element.style.paddingBottom = "35%";
          }
        }
        if (this.isBlurred) {
          this.applyUserVideoFilter(_publisher);
        }
      },
      accessDenied: (): void => {
        if (!this.isBreakout) store.dispatch("update", { isMediaAllowed: false });
      },
      streamCreated: (event: StreamCreatedEvent): void => {
        log("Publisher Stream Created", event);
        sockets.stream.updateStreamId(event.target.stream?.streamId || "MISSING");
        const securityId = this.$auth.authUser.id;
        if (event.target.stream?.connection) {
          const node = VonageUtil.userNameNode(securityId, event.target.stream?.connection.data);
          const mutedNode = VonageUtil.mutedIndicatorNode(event.target.stream.name, this.muteIconSrc);
          if (node) {
            event.target.element?.append(node);
            event.target.element?.append(mutedNode);
          }
          if (Authorization.isObserver()) {
            const iconNode = VonageUtil.employeeIndicatorNode(securityId, employeeLogo);
            event.target.element?.append(iconNode);
          }
          if (event.target.element && this.isBreakout) {
            const initialNode = VonageUtil.hiddenVideoNode(event.target.stream?.connection.data);
            if (initialNode) {
              event.target.element.append(initialNode);
            }
          }
        }
        if (event.target) {
          const securityId = this.$auth.authUser.id;
          (event.target as OT.Publisher).publishVideo(this.hasVideo);
          if (securityId && authorization.isManager(securityId, this.event)) {
            (event.target as OT.Publisher).publishAudio(this.hasAudio);
          }
        }
        this.setPublisher(event.target as OT.Publisher);
      },
      streamDestroyed: (event: StreamDestroyedEvent): void => {
        if (event.reason === "networkDisconnected") console.log("The publisher lost it's connection for user:");
        log("Publisher stream destroyed", event);
      },
      muteForced: (event: unknown): void => {
        log("Publisher mute forced", event);
      },
      audioLevelUpdated: (event: AudioLevelUpdatedEvent): void => {
        if (this.audioLevel > event.audioLevel && event.audioLevel > 0.1) {
          if (!this.isBreakout) {
            if (this.$auth.authUser.id !== store.state.activeSpeakerId) {
              sockets.general.send("", "activeSpeaker.claim");
            }
          } else {
            if (this.$auth.authUser.id !== store.state.breakoutActiveSpeakerId) {
              if (this.getActiveBreakoutSelf) {
                this.getActiveBreakoutSelf.users?.forEach((user) => {
                  sockets.general.send("", "activeSpeaker.claim.breakout", user.securityId);
                });
              }
            }
          }
        }
        this.audioLevel = event.audioLevel;
      },
    });
    return _publisher;
  }

  @Watch("session")
  sessionUpdate(): void {
    if (!this.session) return;
    this.session?.connect(this.token, async (error) => {
      if (error) genericErrors(error);
      else {
        let id = "";
        if (this.isBreakout) {
          id = "breakout-users";
        } else if (this.isSysCheck) {
          id = "system-check-camera";
        } else {
          let group = VideoContainerId.LEARNERS;
          const securityId = this.$auth.authUser.id;
          if (securityId && authorization.isTrainer(securityId, this.event)) {
            group = VideoContainerId.TRAINERS;
          }
          if (
            (securityId && authorization.isModerator(securityId, this.event)) ||
            authorization.isObserver(securityId, this.event)
          ) {
            group = VideoContainerId.MODERATORS;
          }
          id = `${group}-video-container`;
        }
        const publisher = this.init("append", id, true);
        this.session?.publish(publisher, publishErrors);
        this.muteIconSrc = await VonageUtil.getMuteIconSrc();
      }
    });
  }
}
</script>
