import { Module } from "vuex";
import { GridGetters, GridState, ResizingOption } from "./models";
import { RootState } from "@/types";

const GridModule: Module<GridState, RootState> = {
  namespaced: true,
  state() {
    return {
      adminCols: 2,
      usersCols: 0,
      chatCols: 0,
      resizingOption: ResizingOption.NONE,
    };
  },
  getters: {
    isResizing(state: GridState): boolean {
      return state.resizingOption != ResizingOption.NONE;
    },
    adminCenterWidth(state: GridState): number {
      return 12 - state.adminCols - state.usersCols - state.chatCols;
    },
    learnerCenterWidth(state: GridState): number {
      return 12 - state.usersCols - state.chatCols;
    },
    adminStart(): number {
      return 0;
    },

    adminEnd(state: GridState, getters: GridGetters): number {
      return getters.adminStart + state.adminCols;
    },

    usersStart(state: GridState, getters: GridGetters): number {
      return getters.usersEnd - state.usersCols;
    },

    usersEnd(_, getters: GridGetters): number {
      return getters.chatStart - 1;
    },

    chatStart(state: GridState, getters: GridGetters): number {
      return getters.chatEnd - state.chatCols;
    },

    chatEnd(): number {
      return 11;
    },
    validAdminIndex(state: GridState): (idx: number) => boolean {
      return (idx: number): boolean => {
        return idx >= 2 && idx <= 10 - state.chatCols - state.usersCols;
      };
    },
    validUsersIndex(_, getters): (idx: number) => boolean {
      return (idx: number): boolean => {
        return idx < getters.chatStart && idx > getters.adminEnd + 2;
      };
    },

    validChatIndex(state: GridState, getters: GridGetters): (idx: number) => boolean {
      return (idx: number): boolean => {
        return idx > getters.adminEnd + state.usersCols + 2 && idx < 11;
      };
    },
  },
  mutations: {
    setGridState(state: GridState, changes: Partial<GridState>): void {
      Object.assign(state, changes);
    },
  },
};

export default GridModule;
