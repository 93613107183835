<template lang="pug">
.base-layout-container
  LockedHeader
  ConfirmPractice(v-if="practiceConfirm")
  component.content-component(:is="asyncComponent", :content="getActiveContent")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Component as VueComponent } from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import ConfirmPractice from "../Popup/ConfirmPractice.vue";
import LockedHeader from "./LockedHeader.vue";
import { EventContentModel } from "@cruciallearning/puddle";
import { Frame } from "@/types/course";

@Component({
  components: { ConfirmPractice, LockedHeader },
  computed: {
    ...mapGetters("CourseModule", ["getActiveContent"]),
    ...mapState(["practiceConfirm"]),
  },
  methods: {
    ...mapActions("SidebarModule", ["openSideView"]),
  },
})
export default class BaseLayout extends Vue {
  private getActiveContent!: Partial<Frame[]>;
  private readonly event!: EventContentModel;

  get eventSessionCount(): number {
    return this.event.learningSessions.length;
  }

  get asyncComponent(): () => Promise<VueComponent> {
    let layout = FrameType.CONTENT;
    if (this.getActiveContent && this.getActiveContent.length > 1) {
      if (this.getActiveContent[1]?.video) layout = FrameType.VIDEO;
      if (this.getActiveContent[1]?.poll) layout = FrameType.POLL;
    }
    return async (): Promise<VueComponent> => import(`@/components/Layout/Layouts/${layout}Layout`);
  }
}
export enum FrameType {
  CONTENT = "Content",
  POLL = "Poll",
  VIDEO = "Video",
}
</script>
<style lang="scss" scoped>
.button:hover {
  box-shadow: none;
  border-color: none;
}
.base-layout-container {
  overflow: hidden;
  position: relative;
}
</style>
