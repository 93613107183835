<template lang="pug">
.locked-header
  .practice-banner(v-if="showPracticeBanner")
    p Practice Mode | Not to be used in training
  .start-session-container(v-if="showStartClassroomButton")
    .start-session-btn
      Button(:text="startSessionText", data-name="start-session-button", @click="showPopup = true")
  Popup.mb-20(
    closeButton,
    width="400px",
    cancelDisabled=false,
    ok="LET'S GO",
    cancel="CANCEL",
    :closeOnOkClick="true",
    :visible="showPopup",
    @cancel="showPopup = false",
    @ok="onStartClassroom",
    data-name="startClassConfirmation"
  )
    p 
      strong Start Class and Admit All Learners?
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { BaseButton as Button, EventContentModel } from "@cruciallearning/puddle";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { BasePopup as Popup } from "@cruciallearning/puddle";
import { StartClassroom, RestartClassroom, SetRootState } from "@/types";
import { http } from "@/http";
import config from "@/config";
import HandsRaised from "@/components/Header/HandsRaised.vue";

@Component({
  components: { HandsRaised, Button: Button, Popup },
  computed: {
    ...mapState(["isClassroomStarted", "isClassroomWaiting", "practiceConfirm"]),
    ...mapState("EventModule", ["event"]),
    ...mapGetters("EventModule", ["sessionNumber", "restartSessionNumber", "isRestarting"]),
  },
  methods: {
    ...mapActions(["startClassroom", "restartClassroom"]),
    ...mapMutations(["setRootState"]),
  },
})
export default class LockedHeader extends Vue {
  private readonly event!: EventContentModel;
  private readonly sessionNumber!: number;
  private readonly restartSessionNumber!: number;
  private readonly isRestarting!: boolean;
  private readonly isClassroomStarted!: boolean;
  private readonly practiceConfirm!: boolean;
  private readonly startClassroom!: StartClassroom;
  private readonly restartClassroom!: RestartClassroom;
  private readonly setRootState!: SetRootState;
  private showPopup = false;

  get showStartClassroomButton(): boolean {
    return !this.isClassroomStarted && this.$auth.ADMIN && !this.practiceConfirm;
  }

  get startSessionText(): string {
    if (this.event.practice) {
      return "Start Practice Session";
    }
    const prompt = this.isRestarting ? "Restart" : "Start";
    const displayNumber = this.isRestarting ? `${this.restartSessionNumber}` : `${this.sessionNumber}`;
    return `${prompt} Session ${displayNumber}`;
  }

  get eventSessionCount(): number {
    return this.event.learningSessions.length;
  }

  get showPracticeBanner(): boolean {
    return this.event.practice && !this.practiceConfirm;
  }
  onStartClassroom(): void {
    this.showPopup = false;
    if (!this.isClassroomStarted) {
      this.$api.auditron.add(
        config.backendUrl,
        {
          action: "SESSION_START",
          targetObjectId: this.event.id,
          targetCollection: "learningEvent",
          salesforceId: this.event.salesForceId,
          tags: [
            "virtual",
            "session",
            "start",
            this.event.referenceNumber,
            `${this.$auth.authUser.given_name} ${this.$auth.authUser.family_name}`,
          ],
          changes: [
            {
              scope: this.isRestarting ? "events.sessions.restart" : "events.sessions.start",
              newValue: this.sessionNumber.toString(),
            },
          ],
        },
        http
      );

      if (this.isRestarting) {
        this.restartClassroom(this.event.id);
      } else {
        this.startClassroom();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.locked-header {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 13;
  z-index: 501;
  height: fit-content;
  position: absolute;
  width: 100%;
}
.practice-banner {
  background-color: #ff993b;
  z-index: 50;
  text-align: left;
  width: 100%;
  height: 64px;
  border-bottom: 1px #d6d6d6 solid;
  display: flex;
}
.practice-banner p {
  margin: auto 0 auto 16px;
  color: black;
  text-align: left;
  font-family: Flama;
  font-size: 20px;
  font-style: normal;
  font-weight: 650;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.start-session-container {
  margin: 1rem auto 0 auto;
  width: 100%;
}

.start-session-btn {
  width: fit-content;
  margin: 0 auto;
  overflow: hidden;
  filter: drop-shadow(2px 2px 1px var(--gray-50));
}

.start-session-btn:hover {
  filter: none;
}

.start-session-btn .button {
  background-color: #ed1b2f;
  border: none;
  border-radius: 100px;
  margin: auto;
}
.start-session-btn .button:hover {
  background-color: #ec5056 !important;
}

.start-session-btn .button ::v-deep .dx-button-text {
  padding: 0 3vw;
  font-size: 1.5vw;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  white-space: nowrap;
}

.button {
  padding: 12px 11px !important;
}
</style>
