import config from "@/config";
import signal from "signal-js";
import WebSocketAsPromised from "websocket-as-promised";
import { pulse } from "../pulse";
import store from "@/store";
export interface MessageModel {
  [key: string]: string | number | string[] | number[];
  type: string;
}
class Socket {
  private isConnected = false;

  public endpoint = "";
  public connection?: WebSocketAsPromised;

  onOpenCallBack: () => void;
  onCloseCallBack: () => void;
  onMessageCallBack: () => void;

  socketUrl: string;

  constructor(
    callbacks: {
      onOpen: () => void;
      onClose: () => void;
      onMessage: () => void;
    },
    socketUrl: string
  ) {
    this.onOpenCallBack = callbacks.onOpen;
    this.onCloseCallBack = callbacks.onClose;
    this.onMessageCallBack = callbacks.onMessage;
    this.socketUrl = socketUrl;
  }

  get isOpen(): boolean {
    return this.connection?.isOpened || false;
  }

  init(token: string): void {
    if (this.connection) this.connection.removeAllListeners();
    const eventId = localStorage.getItem("CL-LearningEventId");
    this.endpoint = `${this.socketUrl}?trainingEventId=${eventId}&token=${token}`;
    this.connection = new WebSocketAsPromised(this.endpoint, {
      packMessage: (data) => JSON.stringify(data),
      unpackMessage: (response) => JSON.parse(response as string),
    });
    this.connection.onError.addListener(this.onError);
    this.connection.onUnpackedMessage.addListener(this.onMessage.bind(this));
    this.connection.onClose.addListener(this.onClose.bind(this));
    this.connection.onOpen.addListener(this.onOpen.bind(this));
  }

  connect(token: string): void {
    this.isConnected = false;
    this.init(token);
    try {
      this.connection?.open();
    } catch (error) {
      console.log(error);
    }
  }

  disconnect(): void {
    if (!this.connection) return;
    try {
      this.connection.close();
    } catch (error) {
      console.log(error);
    }
  }

  onOpen(): void {
    console.log("--Socket Connected-- ");
    this.isConnected = true;
    this.onOpenCallBack();
  }

  onClose(): void {
    console.log("--Socket Disconnected--");
    this.onCloseCallBack();
  }

  send(data: unknown): void {
    if (!this.isConnected) return;
    console.log("Sent: ", JSON.stringify(data));
    this.connection?.sendPacked(data);
  }

  onMessage(response: MessageModel): void {
    console.log("Received: ", response);
    this.onMessageCallBack();
    signal.emit(response.type.split(".")[0], response);
  }

  onError(error: Error): void {
    console.log("--Socket Error--");
    console.log(error);
  }
}
const socketCallBacks = {
  onOpen(): void {
    pulse.start();
    store.commit("setRootState", {
      wasDisconnected: false,
      wasRemoved: false,
      isConnected: true,
    });
  },
  onClose(): void {
    pulse.stop();
    store.commit("setRootState", {
      isBreakout: false,
      isCheckingHardware: false,
      isClasroomConnected: false,
      isClassroomConnecting: false,
      isConnected: false,
      wasDisconnected: true,
    });
  },
  onMessage(): void {
    pulse.update();
  },
};
export default new Socket(socketCallBacks, config.socketUrl);
