<template lang="pug">
.trainer-attendee-menu
  ul.list-unstyled
    template(v-if="streamEnabled && !isActive")
      li(v-if="$auth.ADMIN", @click="onMute(mediaUpdateType.LEARNERS)", data-name="mute-all-learners") Mute Learners Only
      li(v-if="$auth.ADMIN", @click="onMute(mediaUpdateType.ALL)", data-name="mute-all-participants") Mute All
      li.list-separator(v-if="$auth.ADMIN")
    li(
      v-if="!wasDisconnected && isMediaAllowed && streamEnabled",
      @click="onHardwareClick",
      data-name="change-mic-or-camera"
    ) Mic/Camera Settings
    li(v-if="learnerGuideEnabled", :class="lgClass", @click="onDownloadGuide", data-name="download-guide") {{ lgText }}
    li(v-if="$auth.ADMIN && chatEnabled", @click="downloadChat", data-name="download-general-chat") Download Chat
    li(v-if="$auth.ADMIN", @click="openAttendanceRecord", data-name="download-attendance") View Attendance
    li(v-if="streamEnabled")
      .debug-button(:class="debugClass")
        Icon.debug-icon(:value="debugIcon", :isCourse="true", @click="toggleDebugMode")
        span Debugging Mode
    li
      a.with-icon(
        data-name="help-button",
        href="https://help.cruciallearning.com/s/article/CLP-Virtual-Session-Troubleshooting",
        target="_blank"
      ) Help
        i.icon-external-link
</template>

<script lang="ts">
import { ChatModel, Mute, MediaUpdateType, SetRootState, SetCheckingHardware } from "@/types";
import Download from "@/utils/download";
import { EventContentModel } from "@cruciallearning/puddle";
import { Icon } from "@cruciallearning/puddle/components";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

@Component({
  components: { Icon },
  computed: {
    ...mapState("ChatModule", ["main"]),
    ...mapState(["wasDisconnected", "isMediaAllowed", "debugEnabled"]),
    ...mapState("EventModule", ["event"]),
    ...mapState("BreakoutsModule", ["isActive"]),
    ...mapGetters("EventModule", ["streamEnabled", "learnerGuideEnabled", "chatEnabled"]),
  },
  methods: {
    ...mapActions("UsersModule", ["mute"]),
    ...mapMutations(["setCheckingHardware", "setRootState"]),
  },
})
export default class FunctionMenu extends Vue {
  private readonly mute!: Mute;
  private readonly main!: ChatModel[];
  private readonly event!: EventContentModel;
  private readonly setRootState!: SetRootState;
  private mediaUpdateType: unknown = MediaUpdateType;
  private readonly setCheckingHardware!: SetCheckingHardware;
  private readonly debugEnabled!: boolean;
  get lgText(): string {
    if (this.$auth.ADMIN) return "Download Learner Guide";
    return "Download Guide";
  }

  get lgClass(): string {
    if (this.isPractice) return "disabled";
    return "";
  }

  get isPractice(): boolean {
    if (this.event) return this.event.practice;
    return false;
  }

  get debugClass(): string {
    return this.debugEnabled ? "debug-mode-enabled" : "debug-mode-disabled";
  }

  get debugIcon(): string {
    return this.debugEnabled ? "Bug On" : "Bug Off";
  }

  onMute(type: MediaUpdateType, securityId?: string): void {
    this.mute({ type, securityId });
    this.$emit("hiding");
  }

  onHardwareClick(): void {
    this.$emit("hiding");
    this.setCheckingHardware(true);
  }

  downloadChat(): void {
    Download.saveChatList(this.main);
    this.$emit("hiding");
  }
  toggleDebugMode(): void {
    this.setRootState({ debugEnabled: !this.debugEnabled });
  }
  async onDownloadGuide(): Promise<void> {
    this.$emit("hiding");
    await Download.downloadLearnerGuide();
  }

  openAttendanceRecord(): void {
    this.setRootState({ learnerAttendance: true });
    this.$emit("hiding");
  }
}
</script>
<style scoped lang="scss">
li.list-separator {
  background-color: var(--gray-80);
  padding: unset !important;
}
li.list-separator:after {
  content: "";
  display: block;
  width: 80%;
  margin: 0 auto 0;
  border-bottom: 1px solid var(--gray-20);
}
li:has(.debug-button):hover {
  background-color: var(--gray-80);
  color: var(--gray-20);
  cursor: default;
}
.with-icon {
  display: inline-grid;
  grid-template: 1fr / 10fr 1fr;
  color: var(--gray-20) !important;
  font-size: 16px;
}
.debug-button {
  display: flex;
  .debug-icon {
    margin-right: 0.3rem;
    height: fit-content;
    width: fit-content;
    display: flex;
    border: 1px solid var(--gray-80);
    border-radius: 5px;
  }
  .debug-icon:hover {
    background-color: var(--gray-60);
    border: 1px solid var(--gray-60);
  }
  & > span {
    display: inline-flex;
    align-items: center;
  }
  &.debug-mode-enabled {
    ::v-deep svg {
      path {
        fill: #6ddc8f;
      }
    }
  }
  &.debug-mode-disabled {
    ::v-deep svg {
      path {
        fill: #ea1a29;
      }
    }
  }
  ::v-deep {
    svg {
      vertical-align: text-top;
      height: 25px;
      width: 25px;
      padding: 5px;
    }
  }
}
</style>
