import { IdNameModel } from "./index";

/*
 * ------------------------------------------------------------------------------
 * Models
 * ------------------------------------------------------------------------------
 */

//--> Base State
export interface BaseState {
  layouts: IdNameModel[];
}

export enum LocalStorageFields {
  EVENT_ID = "CL-LearningEventId",
  CAMERA_ID = "CL-DefaultCamera",
  MIC_ID = "CL-DefaultMicrophone",
  ADMIN_COLS = "CL-DefaultAdminCols",
  CHAT_COLS = "CL-DefaultChatCols",
  USERS_COLS = "CL-DefaultUsersCols",
  CAPTIONS = "CL-DefaultCaptions",
  BACKGROUND_BLUR = "CL-DefaultBackground",
  SPEAKER_ID = "CL-DefaultSpeaker",
  OPEN_GROUPS = "CL-OpenGroups",
  UNREAD_GROUPS = "CL-UnreadGroups",
}
