import VonageUtil from "@/components/Vonage/vonageUtil";
import { RootState } from "@/types";
import { VonageState } from "@/types/vonage";
import { Module } from "vuex";
import { genericErrors, publishErrors } from "@/components/Vonage/ErrorHandler";
import log from "@/components/Vonage/DebugLogger";

const VonageModule: Module<VonageState, RootState> = {
  namespaced: true,
  state(): VonageState {
    return {
      publisher: null,
      subscribers: [],
      session: null,
    };
  },
  mutations: {
    setVonageState(state: VonageState, changes: Partial<VonageState>): void {
      Object.assign(state, changes);
    },
    removeSubscriberIdx(state: VonageState, idx: number) {
      if (idx != -1) {
        state.session?.unsubscribe(state.subscribers[idx]);
        state.subscribers.splice(idx, 1);
      }
    },
    pushSubscriber(state: VonageState, subscriber: OT.Subscriber) {
      state.subscribers.push(subscriber);
    },
  },
  getters: {},
  actions: {
    setPublisher({ commit }, publisher: OT.Publisher | null): void {
      commit("setVonageState", { publisher });
    },
    setSession({ commit }, session: OT.Session | null): void {
      commit("setVonageState", { session });
    },
    clearSubscribers({ commit }): void {
      commit("setVonageState", { subscribers: [] });
    },
    addSubscriber({ commit, dispatch }, subscriber: OT.Subscriber): void {
      dispatch("removeSubscriber", subscriber.stream);
      commit("pushSubscriber", subscriber);
    },
    removeSubscriber({ state, commit }, stream: OT.Stream): void {
      if (stream) {
        const securityId = VonageUtil.getSecurityId(stream);
        if (securityId) {
          const idx = state.subscribers.findIndex((e) => securityId === VonageUtil.getSecurityId(e.stream));
          commit("removeSubscriberIdx", idx);
        }
      }
    },
    muteSelf({ state }): void {
      state.publisher?.publishAudio(false);
    },
    shutterSelf({ state }): void {
      state.publisher?.publishVideo(false);
    },
    applyUserVideoFilter({ state }, publisher: OT.Publisher | null): void {
      if (OT.hasMediaProcessorSupport()) {
        if (publisher) {
          publisher.applyVideoFilter({ type: "backgroundBlur", blurStrength: "high" }).catch(genericErrors);
        } else {
          state.publisher?.applyVideoFilter({ type: "backgroundBlur", blurStrength: "high" }).catch(genericErrors);
        }
      } else {
        log(
          `User with securityId ${VonageUtil.getSecurityId(
            state.publisher?.stream
          )} has a browser that is out of date and does not support the video filter.`,
          state.publisher?.stream
        );
        console.warn(
          `The user with securityId ${VonageUtil.getSecurityId()} has a browser that does not support the video filter.`
        );
      }
    },
    refreshPublisherSettings({ state, rootState, dispatch }): void {
      state.publisher?.setAudioSource(rootState.selectedAudioId).catch(publishErrors);
      state.publisher?.setVideoSource(rootState.selectedVideoId).catch(publishErrors);
      const isBlurred = rootState.MediaModule?.isBlurred || false;
      if (!isBlurred) {
        state.publisher?.clearVideoFilter().catch(genericErrors);
      } else {
        dispatch("applyUserVideoFilter");
      }
    },
  },
};
export default VonageModule;
